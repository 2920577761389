import React, { useState, useEffect } from 'react';
import { useAuth } from '../../commons/Auth';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useParams, useHistory } from 'react-router-dom';

function ActionsField(props) {
  const { data, handleFileListView } = props;

  const [{ token, profile = {} } = {}] = useAuth();
  const alert = useAlert();
  const [t] = useTranslation('CONDOS');

  const history = useHistory();

  return (
    <React.Fragment>
      <div className="flex-check">
        <div
          style={{ cursor: 'pointer' }}
          className="mx-2 px-2"
          onClick={(event) => {
            event.stopPropagation();
            handleFileListView(data.file?.key);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="23"
            fill="currentColor"
            class="bi bi-box-arrow-down"
            viewBox="0 0 16 16"
            style={{ color: 'black' }}
          >
            <path
              fill-rule="evenodd"
              d="M3.5 10a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 0 0 1h2A1.5 1.5 0 0 0 14 9.5v-8A1.5 1.5 0 0 0 12.5 0h-9A1.5 1.5 0 0 0 2 1.5v8A1.5 1.5 0 0 0 3.5 11h2a.5.5 0 0 0 0-1z"
            />
            <path
              fill-rule="evenodd"
              d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708z"
            />
          </svg>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ActionsField;
