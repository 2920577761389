import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { useAuth } from '../../commons/Auth';
import axios from 'axios';
import { DataTable, Field } from '../../commons/DataTable';
import DisponibilityField from './DisponibilityField';
import ContractsCheckbox from './ContractsCheckbox';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import { useLoading } from '../../commons/Loading';
import TypeField from './TypeField';
import AgreementUserField from '../../commons/DataTable/AgreementUserField';

function AttachmentsModal({ agreement, attachments, updateAttachments }) {
  const [t] = useTranslation('AGREEMENTS');
  const alert = useAlert();
  const [{ token, profile = {} } = {}] = useAuth();
  const { showLoading, hideLoading } = useLoading();

  const [attachmentsModal, setAttachmentsModal] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);

  const [queryDocuments, setQueryDocuments] = useQueryParams({
    limitDocuments: withDefault(NumberParam, 10),
    offsetDocuments: withDefault(NumberParam, 0),
  });
  const { limitDocuments, offsetDocuments } = queryDocuments;

  function onFilter(queryDiff) {
    if (
      queryDiff.limitDocuments &&
      queryDiff.limitDocuments !== limitDocuments
    ) {
      queryDiff.offsetDocuments = 0;
    }
    setQueryDocuments(queryDiff);
  }

  const toggleAttachments = () => {
    setAttachmentsModal(!attachmentsModal);
  };

  const onSelectChange = (_id) => {
    setSelectedDocuments((prevSelected) => {
      if (prevSelected.includes(_id)) {
        setSelectedAll(false);
        return prevSelected.filter((value) => value !== _id);
      }
      return [...prevSelected, _id];
    });
  };

  const onRowClick = (row) => {
    const { _id } = row;
    onSelectChange(_id);
  };

  const onSelectAll = (selectAll) => {
    setSelectedAll(selectAll);
    if (!selectAll) {
      return setSelectedDocuments([]);
    }
    setSelectedDocuments(
      documents
        .filter((document) => document._id !== undefined)
        .map(({ _id }) => _id)
    );
  };

  function handleUpdateAttachments() {}

  async function fetchDocuments() {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/documents/${profile._id}`,
      {
        headers: { Authorization: token },
        params: {
          limit: limitDocuments,
          offset: offsetDocuments,
          needed: true,
          forAgreements: true,
          selectedSupplier: agreement.supplierId,
          ...(agreement.condoId && { selectedCondo: agreement.condoId }),
          attachmentTypes: agreement.attachments
            ? agreement.attachments.map((attachment) =>
                attachment.rasCardId ? attachment.rasCardId : attachment.typeId
              )
            : [],
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers } = response;

    setDocuments(data.documents);
    setTotal(+headers['x-total-count']);
  }

  const fetchDocumentTypes = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/document-types`,
      {
        headers: { Authorization: token },
        params: {
          limit: 9999,
          offset: 0,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [] } = response;

    setDocumentTypes(data.documentTypes);
  };

  useEffect(() => {
    fetchDocuments();
  }, [limitDocuments, offsetDocuments, documentTypes, attachments]);

  useEffect(() => {
    fetchDocumentTypes();
  }, []);

  useEffect(() => {
    if (attachmentsModal) {
      const initialSelected = attachments.map((attachment) => attachment._id);
      setSelectedDocuments(initialSelected);
      setSelectedAll(false);
    } else {
      setSelectedDocuments([]);
    }
  }, [attachmentsModal]);

  return (
    <React.Fragment>
      <Button
        color="primary"
        style={{
          margin: 0,
          fontSize: '1rem',
          width: '100%',
          height: '100%',
        }}
        onClick={(event) => {
          event.stopPropagation();
          toggleAttachments();
        }}
      >
        {t('BTN_TITLE_MODAL_ATTACHMENTS')}
      </Button>

      <Modal
        isOpen={attachmentsModal}
        toggle={toggleAttachments}
        className="attachments-modal"
      >
        <ModalHeader
          toggle={toggleAttachments}
          style={{ paddingBottom: '0px' }}
        >
          {t('TITLE_MODAL_ATTACHMENTS')}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleUpdateAttachments();
          }}
        >
          <ModalBody>
            <div
              style={{
                fontSize: '14px',
                fontFamily: '"Roboto Slab", serif',
                textAlign: 'center',
                marginBottom: '10px',
              }}
            >
              Scegli gli allegati dell'amministratore e del fornitore da
              inserire nel tuo contratto
            </div>

            <div
              style={{
                fontWeight: 'bold',
                fontSize: '14px',
                fontFamily: '"Roboto Slab", serif',
                textAlign: 'center',
                marginBottom: '10px',
              }}
            >
              Vuoi un contrato personalizzato?{' '}
              <a href="#" onClick={(e) => e.preventDefault()}>
                Richiedi una consulenza
              </a>
            </div>
            <DataTable
              data={documents}
              total={total}
              offset={offsetDocuments}
              limit={limitDocuments}
              onFilter={onFilter}
              docStepper={true}
              emptyText={t('NO_DATA')}
            >
              <Field
                title="Documento"
                source="file.name"
                className="text-left"
              />
              <TypeField
                title="Tipologia"
                source="type.name"
                className="text-left"
              />
              <DisponibilityField title="Disponibilità" />
              <AgreementUserField
                title="Intestatario"
                source="type.name"
                className="text-left"
              />
              <ContractsCheckbox
                selected={selectedDocuments}
                onSelectChange={onRowClick}
                onClick={(e) => e.stopPropagation()}
                // title={
                //   <input
                //     type="checkbox"
                //     checked={selectedAll}
                //     onChange={({ target: { checked } }) => onSelectAll(checked)}
                //   />
                // }
              />
            </DataTable>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleAttachments}>
              Annulla
            </Button>
            <Button
              color="success"
              onClick={() =>
                updateAttachments(selectedDocuments, toggleAttachments)
              }
              style={{ margin: 0, marginLeft: '5px' }}
            >
              Salva e vai al contratto
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}

export default AttachmentsModal;
