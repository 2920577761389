import React from 'react';

function AgreementUserField({ value, maxLength, data }) {
  if (!value) return <>-</>;

  if (maxLength && typeof value === 'string' && value.length > maxLength) {
    return <>{value.substr(0, maxLength)}...</>;
  }

  return (
    <>
      {data.type?.userType === 'EXTRA'
        ? `Extra`
        : data.type?.userType === 'ADMIN'
        ? `Amministratore`
        : data.type?.userType === 'SUPPLIER'
        ? `Fornitore`
        : data.type?.userType === 'ADMIN_CONDO'
        ? `Condominio`
        : data.type?.userType === 'RAS'
        ? `Condominio`
        : '-'}
    </>
  );
}

export default AgreementUserField;
