import React, { useEffect, useState } from 'react';
import { useAuth } from '../../commons/Auth';
import { useTranslation } from 'react-i18next';
import { useAlert } from 'react-alert';
import { useLoading } from '../../commons/Loading';
import { Link, Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Footer from '../../commons/Footer';
import { Card, CardBody, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import {
  BtnRadiusWhite,
  ContFlexLeft,
  H4Styled,
} from '../../styled-components';
import { ReactComponent as IconBack } from '../../images/icn-back.svg';
import classNames from 'classnames';
import variables from '../../variables';
import './CondoDetail.scss';
import './TabsProfile.scss';
import CondoInfo from './CondoInfo';
import axios from 'axios';
import CondoDocuments from './CondoDocuments';
import { NumberParam, StringParam, withDefault } from 'serialize-query-params';
import { useQueryParams } from 'use-query-params';
import { uploadCondoDocument } from '../../lib/fetch/condos';
import { deleteCondoFile, deleteFile } from '../../lib/fetch/files';
import CondoAgreements from './CondoAgreements';
const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

function CondosDetail(props) {
  const [{ token, isAdmin, userType = '', profile = {} } = {}] = useAuth();
  const [t] = useTranslation('CONDOS_DETAIL');
  const [pageBack, setPageBack] = useState('');
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const { history, location, match = {} } = props;
  const { params: { id } = {}, url = '' } = match;
  const { pathname = '', state = {} } = location;
  const tab = pathname.split('/').pop();
  const [condo, setCondo] = useState({});
  const [total, setTotal] = useState(0);
  const [condoFiles, setCondoFiles] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [RASTypes, setRASTypes] = useState([]);

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
    active: withDefault(StringParam, localStorage.getItem('active')),
  });

  const { limit, offset, active } = query;

  useEffect(() => {
    if (state.pageBack) setPageBack(state.pageBack);
  }, [state]);

  function onFilter(queryDiff) {
    if (
      (queryDiff.active && queryDiff.active !== active) ||
      (active && queryDiff.active === '') ||
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0;
    }
    setQuery(queryDiff);
  }

  const fetchCondo = async () => {
    showLoading();

    const response = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/condos/detail/${id}`,
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      hideLoading();

      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    const { data = [] } = response;
    setCondo(data);
    hideLoading();
  };

  const fetchCertifiers = async (value) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/profiles/certifier`,
      {
        headers: { Authorization: token },
        params: { search: value },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [] } = response;
    return data;
  };

  async function getCondoFiles() {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/documents/${id}/condo`,
      {
        headers: { Authorization: token },
        params: { limit, offset, active },
      }
    );

    if (response instanceof Error) {
      alert.error(t('COMMON:GENERIC_ERROR'));
      return [];
    }

    const { data = [], headers = {} } = response;

    hideLoading();
    setCondoFiles(data.documents);
    setTotal(+headers['x-total-count']);
    return data;
  }

  const fetchDocumentTypes = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/document-types`,
      {
        headers: { Authorization: token },
        params: {
          limit: 9999,
          offset: 0,
          type:
            profile.type === 'CERTIFIER' ? 'CERTIFIER_CONDO' : 'ADMIN_CONDO',
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [] } = response;

    setDocumentTypes(data.documentTypes);
  };

  const fetchRASTypes = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/document-types`,
      {
        headers: { Authorization: token },
        params: {
          limit: 9999,
          offset: 0,
          type: 'RAS',
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [] } = response;

    setRASTypes(data.documentTypes);
  };

  useEffect(() => {
    fetchCondo();
    fetchDocumentTypes();
    fetchRASTypes();
  }, [id]);

  useEffect(() => {
    getCondoFiles();
  }, [limit, offset, documentTypes]);

  if (pathname === url) {
    return <Redirect to={`${url}/info`} />;
  }

  async function uploadFile(file, toggleAddNewFileModal) {
    showLoading();

    const { error, data } = await uploadCondoDocument({
      id: profile.type === 'ADMIN_COLLAB' ? profile.adminId : profile._id,
      data: file,
      token,
    });

    if (error) {
      hideLoading();

      toggleAddNewFileModal();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    hideLoading();
    toggleAddNewFileModal();
    getCondoFiles();
    alert.success(t('COMMON:SUCCESS_UPLOAD'));
  }

  async function handleDelete({ documentId }) {
    showLoading();

    const { error, data } = await deleteFile({
      documentId,
      token,
    });

    if (error) {
      hideLoading();

      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    getCondoFiles();
    hideLoading();
    alert.success(t('COMMON:SUCCESS_DELETE'));
  }

  return (
    <div>
      <Row>
        <Col sm="12" md="6">
          <ContFlexLeft>
            <BtnRadiusWhite
              onClick={() => {
                if (userType === 'SUPER_ADMIN') {
                  history.replace(`/condos`);
                } else {
                  history.replace(`/my-condos`);
                }
              }}
            >
              <IconBack fill={variables.primary} />
            </BtnRadiusWhite>
            <H4Styled>{t('TITLE_DETAIL')}</H4Styled>
          </ContFlexLeft>
        </Col>
      </Row>

      <div className="m-tb-20">
        <Nav tabs>
          <NavItem>
            <NavLink
              tag={Link}
              to={`${match.url}/info`}
              className={classNames({ active: tab === 'info' })}
            >
              {t('GENERAL_INFO')}
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              tag={Link}
              to={`${match.url}/condo-documents`}
              className={classNames({ active: tab === 'condo-documents' })}
            >
              {t('TABS:CONDO_DOCUMENTS')}
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              tag={Link}
              to={`${match.url}/agreements`}
              className={classNames({ active: tab === 'agreements' })}
            >
              {t('TABS:CONDO_AGREEMENTS')}
            </NavLink>
          </NavItem>
        </Nav>

        <Switch>
          <Route
            path={`${match.url}/info`}
            component={(props) => (
              <CondoInfo
                id={id}
                data={condo}
                fetchCertifiers={fetchCertifiers}
                {...props}
              />
            )}
          />

          <Route
            path={`${match.url}/condo-documents`}
            component={(props) => (
              <Card>
                <CardBody>
                  <CondoDocuments
                    profile={profile}
                    condo={condo}
                    fetchCondo={fetchCondo}
                    query={query}
                    total={total}
                    onFilter={onFilter}
                    condoFiles={condoFiles}
                    uploadFile={uploadFile}
                    handleDelete={handleDelete}
                    documentTypes={documentTypes}
                    RASTypes={RASTypes}
                    getCondoFiles={getCondoFiles}
                  ></CondoDocuments>
                </CardBody>
              </Card>
            )}
          />

          <Route
            path={`${match.url}/agreements`}
            component={(props) => (
              <Card>
                <CardBody>
                  <CondoAgreements
                    profile={profile}
                    data={condo}
                  ></CondoAgreements>
                </CardBody>
              </Card>
            )}
          />
        </Switch>
      </div>
      <Footer />
    </div>
  );
}

export default withRouter(CondosDetail);
