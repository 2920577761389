import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../commons/Auth';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../../commons/Loading';
import { useAlert } from 'react-alert';
import axios from 'axios';
import { NumberParam, StringParam, withDefault } from 'serialize-query-params';
import { useQueryParams } from 'use-query-params';
import {
  deleteFile,
  getAllProfileFiles,
  getSignedUrl,
} from '../../../lib/fetch/files';
import ProfileDocumentsPresentational from './ProfileDocumentsPresentational';
import { upload, uploadFileByUser } from '../../../lib/fetch/profiles';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const ProfileDocuments = ({ profile = {}, id }) => {
  const [{ token, isAdmin } = {}] = useAuth();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();
  const [allProfileFiles, setAllProfileFiles] = useState([]);
  const [total, setTotal] = useState(0);
  const [updatedProfile, setUpdatedProfile] = useState(profile);
  const [documentTypes, setDocumentTypes] = useState([]);

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
    active: withDefault(StringParam, localStorage.getItem('active')),
  });

  const { limit, offset, active } = query;

  function onFilter(queryDiff) {
    if (
      (queryDiff.active && queryDiff.active !== active) ||
      (active && queryDiff.active === '') ||
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0;
    }
    setQuery(queryDiff);
  }

  async function getProfileDocuments() {
    showLoading();

    const { data, error, headers = {} } = await getAllProfileFiles(
      profile._id,
      limit,
      offset,
      token,
      active
    );

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setAllProfileFiles(data.documents);
    setTotal(+headers['x-total-count']);
    hideLoading();
  }

  async function handleFileListView(key) {
    showLoading();

    const { data, error } = await getSignedUrl(key, token);

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    hideLoading();
    window.open(data.url, '_blank');
  }

  async function uploadFile(file, toggleAddNewFileModal) {
    showLoading();

    const { error, data } = await uploadFileByUser({
      id: profile.type === 'ADMIN_COLLAB' ? profile.adminId : profile._id,
      data: file,
      token,
    });

    if (error) {
      hideLoading();

      toggleAddNewFileModal();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setUpdatedProfile(data);
    hideLoading();
    toggleAddNewFileModal();
    alert.success(t('COMMON:SUCCESS_UPLOAD'));
  }

  async function handleDelete({ documentId, key }) {
    showLoading();

    const { error, data } = await deleteFile({
      documentId,
      key,
      token,
    });

    if (error) {
      hideLoading();

      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    setUpdatedProfile(data);
    hideLoading();
    alert.success(t('COMMON:SUCCESS_DELETE'));
  }

  const fetchDocumentTypes = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/document-types`,
      {
        headers: { Authorization: token },
        params: {
          limit: 9999,
          offset: 0,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [] } = response;

    setDocumentTypes(data.documentTypes);
  };

  useEffect(() => {
    if (profile._id) {
      getProfileDocuments();
    }
  }, [limit, offset, active, updatedProfile, documentTypes]);

  useEffect(() => {
    fetchDocumentTypes();
  }, []);

  return (
    <ProfileDocumentsPresentational
      data={allProfileFiles}
      profile={updatedProfile}
      query={query}
      total={total}
      onFilter={onFilter}
      handleFileListView={handleFileListView}
      uploadFile={uploadFile}
      handleDelete={handleDelete}
      documentTypes={documentTypes}
    />
  );
};

export default ProfileDocuments;
