import React, { useState } from 'react';
import { CustomInput, Form } from 'reactstrap';
import axios from 'axios';
import { useAlert } from 'react-alert';
import { useLoading } from '../../../commons/Loading';
import { useAuth } from '../../../commons/Auth';
import { useTranslation } from 'react-i18next';
import { ADMIN_TYPES, CERTIFIER_TYPE } from '../../../App';

function SwitchField(props) {
  const [t] = useTranslation('FILES');
  const {
    value = false,
    maxLength,
    index,
    data,
    condo,
    disabled = false,
    getCondoFiles,
  } = props;
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const [{ token, profile = {} } = {}] = useAuth();

  const [isNeeded, setIsNeeded] = useState(
    !data._id || value === 'false' || value === false ? false : true
  );

  const updateFile = async () => {
    try {
      showLoading();

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/documents/${data._id}`,
        {
          needed: value === 'false' || value === false ? false : true,
        },
        {
          headers: { Authorization: token },
        }
      );

      if (response instanceof Error) {
        hideLoading();
        alert.error(t('COMMON:GENERIC_ERROR'));
        return { error: response };
      }

      getCondoFiles();
      hideLoading();
      alert.success(t('COMMON:SUCCESS_UPDATE'));
      return response;
    } catch (error) {
      // Sentry TODO
      return { error };
    }
  };

  return (
    <>
      <div>
        <Form>
          <CustomInput
            type="checkBox"
            id={`switch${index}`}
            name="customSwitch"
            checked={isNeeded}
            onChange={() => {
              updateFile();
              setIsNeeded(!isNeeded);
            }}
            disabled={
              data.type?.required
                ? true
                : !data._id
                ? true
                : disabled || profile.type === CERTIFIER_TYPE.CERTIFIER
                ? profile._id !== data.profileId
                : ![...ADMIN_TYPES, 'ADMIN_COLLAB'].includes(profile.type)
            }
          />
        </Form>
      </div>
    </>
  );
}

export default SwitchField;
