import React, { useEffect, useState } from 'react';
import { useAuth } from '../../commons/Auth';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../commons/Loading';
import { useAlert } from 'react-alert';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import axios from 'axios';
import NotificationsPresentational from './NotificationsPresentational';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const Notifications = ({ profile }) => {
  const [{ token, isAdmin } = {}] = useAuth();
  const [t] = useTranslation();
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();

  const [notifications, setNotifications] = useState([]);
  const [total, setTotal] = useState(0);

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
    sender: withDefault(StringParam, ''),
    type: withDefault(StringParam, ''),
  });

  const { limit, offset, type, sender } = query;

  function onFilter(queryDiff) {
    if (
      (queryDiff.sender && queryDiff.sender !== sender) ||
      (sender && queryDiff.sender === '') ||
      (queryDiff.type && queryDiff.type !== type) ||
      (type && queryDiff.type === '') ||
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0; // return to first page
    }

    setQuery(queryDiff);
  }

  const fetchNotifications = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/notifications`,
      {
        headers: { Authorization: token },
        params: {
          limit,
          offset,
          type,
          sender,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers = {} } = response;

    setNotifications(data.notifications);
    setTotal(+headers['x-total-count']);
  };

  const updateNotifications = async () => {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/notifications`,
      {},
      {
        headers: { Authorization: token },
      }
    );

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    fetchNotifications();
  };

  useEffect(() => {
    fetchNotifications();
    updateNotifications();
  }, [limit, offset, type, sender]);

  return (
    <NotificationsPresentational
      data={notifications}
      onFilter={onFilter}
      query={query}
      limit={limit}
      offset={offset}
      total={total}
      profile={profile}
    />
  );
};

export default Notifications;
