import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { ContPaginationPage } from '../../../styled-components';
import { useAuth } from '../../../commons/Auth';
import { DataTable, Field } from '../../../commons/DataTable';
import generatePaginationOptions from '../../../lib/helpers/generatePaginationOptions';
import ContractsCheckbox from './ContractsCheckbox';
import { useAlert } from 'react-alert';
import DisponibilityField from './DisponibilityField';
import TypeField from './TypeField';
import AgreementUserField from '../../../commons/DataTable/AgreementUserField';

const INITAL_CONTRACT_FORM_VALUES = {
  name: '',
  condo: '',
  activity: '',
  type: '',
  documentType: '',
  supplier: '',
  attachedFile: '',
};

const INITAL_CONTRACT_ERRORS = {
  name: false,
  condo: false,
  activity: false,
  type: false,
  documentType: false,
  supplier: false,
};

function AddContractStepper({
  profile,
  condos,
  activities,
  documentTypes,
  agreementTypes,
  suppliers,
  documents,
  onFilter,
  total,
  limit,
  offset,
  onSelectChange,
  onSelectAll,
  selectedAll,
  selectedDocuments,
  fetchSuppliers,
  fetchAgreementTypes,
  uploadAgreements,
  setSelectedCondo,
  setSelectedSupplier,
}) {
  const [t] = useTranslation('AGREEMENTS_CONDO_SUPPLIER');
  const alert = useAlert();
  const [{ isAdmin } = {}] = useAuth();

  const [addNewContractModal, setAddNewContractModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [formValues, setFormValues] = useState(INITAL_CONTRACT_FORM_VALUES);
  const [errors, setErrors] = useState(INITAL_CONTRACT_ERRORS);

  const toggleAddAgreementModal = () => {
    setCurrentStep(1);
    setFormValues(INITAL_CONTRACT_FORM_VALUES);
    setErrors(INITAL_CONTRACT_ERRORS);
    setAddNewContractModal(!addNewContractModal);
  };

  function handleContractChange(redirect = false) {
    const name = formValues.name;
    const condo = formValues.condo;
    const activity = formValues.activity;
    const type = formValues.type;
    const supplier = formValues.supplier;
    const attachedFile = formValues.attachedFile;
    const agreementType = 'SUPPLIER_CONDO';
    const documentType = formValues.documentType;

    let newErrors = {};
    if (formValues.attachedFile !== '' && formValues.documentType === '') {
      newErrors.documentType = true;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors({ ...errors, ...newErrors });
      return;
    }

    const fileSize = (attachedFile.size / 1024 ** 2).toFixed(2);

    if (fileSize > 20) {
      alert.info('File troppo grande. Max 20Mb');
    } else {
      const data = new FormData();
      data.append('name', name);
      data.append('condo', condo);
      data.append('activity', activity);
      data.append('type', type);
      data.append('supplier', supplier);
      data.append('file', attachedFile);
      data.append('agreementType', agreementType);
      data.append('selectedDocuments', JSON.stringify(selectedDocuments));
      data.append('documentType', documentType);

      uploadAgreements(data, toggleAddAgreementModal, redirect);
    }
  }

  const goToNextStep = () => {
    if (currentStep === 1) {
      let newErrors = {};
      if (formValues.name === '') {
        newErrors.name = true;
      }
      if (formValues.condo === '') {
        newErrors.condo = true;
      }
      if (formValues.activity === '') {
        newErrors.activity = true;
      }
      if (formValues.type === '') {
        newErrors.type = true;
      }
      if (formValues.supplier === '') {
        newErrors.supplier = true;
      }

      if (Object.keys(newErrors).length > 0) {
        setErrors({ ...errors, ...newErrors });
        return;
      }
    }

    if (currentStep < 3) setCurrentStep(currentStep + 1);
  };

  const goToPreviousStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  const onRowClick = (row) => {
    const { _id } = row;
    onSelectChange(_id, row.typeId);
  };

  useEffect(() => {
    if (formValues.activity && formValues.activity !== '') {
      fetchSuppliers(formValues.activity);
      fetchAgreementTypes(formValues.activity);
    }
  }, [formValues.activity]);

  useEffect(() => {
    onFilter({ offsetDocuments: 0, limitDocuments: limit });
  }, [currentStep]);

  return (
    <React.Fragment>
      <Button
        color="primary"
        style={{
          margin: 0,
          fontSize: '1rem',
          width: '100%',
        }}
        onClick={(event) => {
          event.stopPropagation();
          toggleAddAgreementModal();
        }}
      >
        {t('BTN_TITLE')}
      </Button>

      <Modal
        isOpen={addNewContractModal}
        toggle={toggleAddAgreementModal}
        className="new-file-modal agreement-label"
      >
        <ModalHeader
          toggle={toggleAddAgreementModal}
          style={{ paddingBottom: '0px' }}
        >
          {t('TITLE')}
        </ModalHeader>
        <ModalBody>
          {currentStep === 1 && (
            <>
              <div
                style={{
                  fontSize: '14px',
                  fontFamily: '"Roboto Slab", serif',
                  textAlign: 'center',
                  marginBottom: '10px',
                }}
              >
                Inserisci le prime informazioni
              </div>
              <Row>
                <Col sm="12">
                  <FormGroup>
                    <Label>{t('NAME')}</Label>
                    <Input
                      type="text"
                      name="name"
                      maxLength="50"
                      defaultValue={formValues.name}
                      placeholder={t('SELECT_NAME')}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          name: e.target.value,
                        });
                        setErrors({
                          ...errors,
                          name: false,
                        });
                      }}
                    />
                    {errors.name === true && (
                      <FormText className="error-message">
                        {t('NAME_ERROR')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>

                <Col sm="12">
                  <FormGroup>
                    <Label>{t('CONDO')}</Label>
                    <CustomInput
                      id="condo"
                      type="select"
                      name="condo"
                      value={formValues.condo}
                      placeholder={t('SELECT')}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          condo: e.target.value,
                        });
                        setErrors({
                          ...errors,
                          condo: false,
                        });
                        setSelectedCondo(e.target.value);
                      }}
                      style={{
                        color: formValues.condo === '' ? '#aaa' : '#000',
                      }}
                    >
                      <option value="" className="placeholder-option">
                        Seleziona un condominio
                      </option>
                      {condos.map((condo, i) => (
                        <option key={i} value={condo._id}>
                          {condo.name}
                        </option>
                      ))}
                    </CustomInput>
                    {errors.condo === true && (
                      <FormText className="error-message">
                        {t('CONDO_ERROR')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>

                <Col sm="12">
                  <FormGroup>
                    <Label>{t('ACTIVITY')}</Label>
                    <CustomInput
                      id="activity"
                      type="select"
                      name="activity"
                      value={formValues.activity}
                      placeholder={t('SELECT')}
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          activity: e.target.value,
                          supplier: '',
                          type: '',
                        });
                        setErrors({
                          ...errors,
                          activity: false,
                        });
                      }}
                      style={{
                        color: formValues.activity === '' ? '#aaa' : '#000',
                      }}
                    >
                      <option value="" className="placeholder-option">
                        Seleziona un'attività
                      </option>
                      {activities.map((activity, i) => (
                        <option key={i} value={activity._id}>
                          {activity.name}
                        </option>
                      ))}
                    </CustomInput>
                    {errors.activity === true && (
                      <FormText className="error-message">
                        {t('ACTIVITY_ERROR')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>

                <Col sm="12">
                  <FormGroup>
                    <Label>{t('TYPE')}</Label>
                    <CustomInput
                      id="type"
                      type="select"
                      name="type"
                      value={formValues.type}
                      placeholder={t('SELECT')}
                      disabled={
                        !formValues.activity && formValues.activity === ''
                      }
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          type: e.target.value,
                        });
                        setErrors({
                          ...errors,
                          type: false,
                        });
                      }}
                      style={{
                        color: formValues.type === '' ? '#aaa' : '#000',
                      }}
                    >
                      <option value="" className="placeholder-option">
                        Seleziona il tipo attività
                      </option>
                      {agreementTypes.map((type, i) => (
                        <option key={i} value={type._id}>
                          {type.name}
                        </option>
                      ))}
                    </CustomInput>
                    {errors.type === true && (
                      <FormText className="error-message">
                        {t('TYPE_ERROR')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>

                <Col sm="12">
                  <FormGroup>
                    <Label>{t('SUPPLIER')}</Label>
                    <CustomInput
                      id="supplier"
                      type="select"
                      name="supplier"
                      value={formValues.supplier}
                      placeholder={t('SELECT')}
                      disabled={
                        !formValues.activity && formValues.activity === ''
                      }
                      onChange={(e) => {
                        setFormValues({
                          ...formValues,
                          supplier: e.target.value,
                        });
                        setErrors({
                          ...errors,
                          supplier: false,
                        });
                        setSelectedSupplier(e.target.value);
                      }}
                      style={{
                        color: formValues.supplier === '' ? '#aaa' : '#000',
                      }}
                    >
                      <option value="" className="placeholder-option">
                        Seleziona un fornitore
                      </option>
                      {suppliers.map((supplier, i) => (
                        <option key={i} value={supplier._id}>
                          {supplier.businessName}
                        </option>
                      ))}
                    </CustomInput>
                    {errors.supplier === true && (
                      <FormText className="error-message">
                        {t('SUPPLIER_ERROR')}
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </>
          )}

          {currentStep === 2 && (
            <>
              <div
                style={{
                  fontSize: '14px',
                  fontFamily: '"Roboto Slab", serif',
                  textAlign: 'center',
                  marginBottom: '10px',
                }}
              >
                Scegli gli allegati dell'amministratore e del fornitore da
                inserire nel tuo contratto
              </div>

              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '14px',
                  fontFamily: '"Roboto Slab", serif',
                  textAlign: 'center',
                  marginBottom: '10px',
                }}
              >
                Vuoi un contrato personalizzato?{' '}
                <a href="#" onClick={(e) => e.preventDefault()}>
                  Richiedi una consulenza
                </a>
              </div>
              <DataTable
                data={documents}
                total={total}
                offset={offset}
                limit={limit}
                onFilter={onFilter}
                docStepper={true}
                emptyText={t('COMMON:NO_DATA')}
              >
                <Field
                  title="Documento"
                  source="file.name"
                  className="text-left"
                />
                <TypeField
                  title="Tag"
                  source="type.name"
                  className="text-left"
                />
                <DisponibilityField title="Disponibilità" />
                <AgreementUserField
                  title="Intestatario"
                  source="type.name"
                  className="text-left"
                />
                <ContractsCheckbox
                  selected={selectedDocuments}
                  onSelectChange={onRowClick}
                  onClick={(e) => e.stopPropagation()}
                  title={
                    <input
                      type="checkbox"
                      checked={selectedAll}
                      onChange={({ target: { checked } }) =>
                        onSelectAll(checked)
                      }
                    />
                  }
                />
              </DataTable>
            </>
          )}

          {currentStep === 3 && (
            <>
              <div
                style={{
                  fontSize: '14px',
                  fontFamily: '"Roboto Slab", serif',
                  textAlign: 'center',
                  marginBottom: '5px',
                }}
              >
                <p
                  style={{
                    color: 'red',
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                  }}
                >
                  ATTENZIONE: caricando un documento in questo modo si va ad
                  aggiornare la tipologia del documento selezionato (tranne per
                  i documenti Liberi)!
                </p>
                Sicuro di voler continuare?
              </div>
              <Row>
                <Col sm="12">
                  <FormGroup>
                    <Label for="selectContract">
                      {t('FILES:CHOOSE_FILE')}
                      &nbsp;({t('FILES:MAX_UPLOAD_FILE_SIZE')})
                    </Label>
                    <CustomInput
                      type="file"
                      id="attachedFile"
                      name="attachedFile"
                      onChange={(event) =>
                        setFormValues({
                          ...formValues,
                          attachedFile: event.target.files[0],
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              {formValues.attachedFile !== '' && (
                <div
                  style={{
                    margin: '5px',
                    backgroundColor: '#F7F8FA',
                  }}
                >
                  <Row>
                    <Col sm="12">
                      <span style={{ overflowX: 'hidden' }}>
                        {formValues.attachedFile?.name}
                      </span>
                    </Col>

                    <Col sm="12">
                      <FormGroup>
                        <Label>{t('DOCUMENT_TYPE')}</Label>
                        <CustomInput
                          id="documentType"
                          type="select"
                          name="documentType"
                          value={formValues.documentType}
                          placeholder={t('SELECT')}
                          onChange={(e) => {
                            setFormValues({
                              ...formValues,
                              documentType: e.target.value,
                            });
                            setErrors({
                              ...errors,
                              documentType: false,
                            });
                          }}
                        >
                          <option value="">-</option>
                          {documentTypes.map((type, i) => (
                            <option key={i} value={type._id}>
                              {type.name}
                            </option>
                          ))}
                        </CustomInput>
                        {errors.type === true && (
                          <FormText className="error-message">
                            {t('DOCUMENT_TYPE_ERROR')}
                          </FormText>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={goToPreviousStep}
            disabled={currentStep === 1}
          >
            Indietro
          </Button>
          {currentStep < 3 && (
            <Button
              color="primary"
              onClick={goToNextStep}
              disabled={currentStep === 3}
              style={{ margin: 0, marginLeft: '5px' }}
            >
              Avanti
            </Button>
          )}
          {currentStep === 3 && (
            <Button
              color="success"
              onClick={() => {
                handleContractChange();
              }}
              style={{ margin: 0, marginLeft: '5px' }}
            >
              Salva ed esci
            </Button>
          )}
          {currentStep === 3 && (
            <Button
              color="success"
              onClick={() => handleContractChange(true)}
              style={{ margin: 0, marginLeft: '5px' }}
            >
              Salva e vai al contratto
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}

export default AddContractStepper;
