import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useLoading } from '../../commons/Loading';
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  FormText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { BtnOutlineGreen, BtnOutlineRed } from '../../styled-components';
import axios from 'axios';

const INITAL_FILE_FORM_VALUES = {
  file: '',
  documentType: '',
};

function DocumentModal({ agreement, uploadDocument }) {
  const [t] = useTranslation('AGREEMENTS');
  const alert = useAlert();
  const [{ token, profile = {} } = {}] = useAuth();
  const { showLoading, hideLoading } = useLoading();

  const [modalState, setModalState] = useState(false);
  const [formValues, setFormValues] = useState(INITAL_FILE_FORM_VALUES);
  const [errors, setErrors] = useState({
    file: false,
    documentType: false,
  });
  const [documentTypes, setDocumentTypes] = useState([]);

  const toggle = () => {
    setModalState(!modalState);
  };

  function handleFileChange() {
    const file = formValues.file;
    const documentType = formValues.documentType;
    const validationErrors = errors;

    const fileSize = (file.size / 1024 ** 2).toFixed(2);

    if (fileSize > 20) {
      alert.info('File troppo grande. Max 20Mb');
    } else {
      const data = new FormData();
      data.append('file', file);
      data.append('documentTypeId', documentType);

      validationErrors.file = false;
      validationErrors.documentType = false;

      if (file === '') validationErrors.file = true;
      if (documentType === '') validationErrors.documentType = true;
      setErrors({ ...validationErrors });

      const arrayErrors = Object.values(errors);
      if (!arrayErrors.includes(true)) {
        uploadDocument(data, toggle);
      }
    }
  }

  const fetchDocumentTypes = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/document-types`,
      {
        headers: { Authorization: token },
        params: {
          limit: 9999,
          offset: 0,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [] } = response;

    setDocumentTypes(data.documentTypes);
  };

  useEffect(() => {
    fetchDocumentTypes();
  }, []);

  return (
    <React.Fragment>
      <Button
        color="primary"
        style={{
          margin: 0,
          fontSize: '1rem',
          width: '100%',
          height: '100%',
        }}
        onClick={(event) => {
          event.stopPropagation();
          toggle();
        }}
      >
        {t('ADD_DOCUMENT')}
      </Button>

      <Modal isOpen={modalState} toggle={toggle} className="new-document-modal">
        <ModalHeader toggle={toggle}>{t('FILES:ADD')}</ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleFileChange();
          }}
        >
          <ModalBody>
            <div
              style={{
                fontSize: '14px',
                fontFamily: '"Roboto Slab", serif',
                textAlign: 'center',
                marginBottom: '5px',
              }}
            >
              <p
                style={{
                  color: 'red',
                  textDecoration: 'underline',
                  fontWeight: 'bold',
                }}
              >
                ATTENZIONE: caricando un documento in questo modo si va ad
                aggiornare la tipologia del documento selezionato (tranne per i
                documenti Liberi)!
              </p>
              Sicuro di voler continuare?
            </div>
            <Row>
              <Col sm="12">
                <FormGroup>
                  <Label for="selectFile">
                    {t('FILES:CHOOSE_FILE')}
                    &nbsp;({t('FILES:MAX_UPLOAD_FILE_SIZE')})
                  </Label>
                  <CustomInput
                    type="file"
                    id="fileDocument"
                    name="fileDocument"
                    onChange={(event) =>
                      setFormValues({
                        ...formValues,
                        file: event.target.files[0],
                      })
                    }
                  />
                  {errors.file === true && (
                    <FormText className="error-message">
                      {t('FILE_ERROR')}
                    </FormText>
                  )}
                </FormGroup>
              </Col>
            </Row>
            {formValues.file !== '' && (
              <div
                style={{
                  margin: '5px',
                  backgroundColor: '#F7F8FA',
                }}
              >
                <Row>
                  <Col sm="12">
                    <span style={{ overflowX: 'hidden' }}>
                      {formValues.file?.name}
                    </span>
                  </Col>

                  <Col sm="12">
                    <FormGroup>
                      <Label>{t('DOCUMENT_TYPE')}</Label>
                      <CustomInput
                        id="documentType"
                        type="select"
                        name="documentType"
                        value={formValues.documentType}
                        placeholder={t('SELECT')}
                        onChange={(e) => {
                          setFormValues({
                            ...formValues,
                            documentType: e.target.value,
                          });
                          setErrors({
                            ...errors,
                            documentType: false,
                          });
                        }}
                      >
                        <option value="">-</option>
                        {documentTypes.map((type, i) => (
                          <option key={i} value={type._id}>
                            {type.name}
                          </option>
                        ))}
                      </CustomInput>
                      {errors.documentType === true && (
                        <FormText className="error-message">
                          {t('DOCUMENT_TYPE_ERROR')}
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed type="button" className="uppercase" onClick={toggle}>
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}

export default DocumentModal;
