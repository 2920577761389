import React from 'react';

function TypeField({ value, maxLength, data }) {
  if (!value) return <>-</>;

  if (maxLength && typeof value === 'string' && value.length > maxLength) {
    return <>{value.substr(0, maxLength)}...</>;
  }

  return (
    <>
      {data.type?.userType === 'RAS' ||
      data.type?.name === 'Registro anagrafe sicurezza (RAS) condominiale'
        ? `Registro anagrafe sicurezza (RAS) condominiale - ${
            data.rasCard ? data.rasCard.name : value
          }`
        : value}
    </>
  );
}

export default TypeField;
