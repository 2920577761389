import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BooleanField,
  DataTable,
  DateTimeField,
  Field,
} from '../../commons/DataTable';
import ActionsField from './ActionsField';

function NotificationsTable({ data, total, offset, limit, onFilter, profile }) {
  const [t] = useTranslation('NOTIFICATIONS');

  return (
    <DataTable
      data={data}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      emptyText={t('COMMON:NO_DATA')}
    >
      <Field
        title="Mittente"
        source="sender.businessName"
        className="text-center"
      />
      <Field title="Descrizione" source="description" className="text-center" />
      <Field title="Area" source="type" className="text-center" />
      <BooleanField title="Visualizzato" source="status" />
      <DateTimeField
        title="Data creazione"
        source="createdAt"
        format="DD/MM/YYYY HH:mm"
      />
      <ActionsField title="Azioni" profile={profile} data={data} />
    </DataTable>
  );
}

export default NotificationsTable;
