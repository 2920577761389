import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  TabContent,
  TabPane,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  FormText,
  Nav,
  NavItem,
  NavLink,
  ButtonGroup,
} from 'reactstrap';
import {
  ContAffiliation,
  H5Styled,
  ContTitleAction,
  ContFlexRight,
  SummaryAmountCont,
  BtnOutlineGreen,
  BtnOutlineOrange,
  BtnOutlineRed,
  BtnRadiusRed,
  H4Styled,
} from '../../../styled-components';
import DateInput from '../../../commons/DateInput';
import ReceiptsTable from './ReceiptsTable';
import ModalReceipt from './ModalReceipt';
import { ReactComponent as IconPlus } from '../../../images/icn_plus_green.svg';
import AutoSuggest from '../../../commons/Autosuggest';
import Affiliation from './Affiliation';
import { ReactComponent as IconRenewed } from '../../../images/icn_rinnova.svg';
import ModalConfirm from '../../../commons/ModalConfirm';
import variables from '../../../variables';
import {
  ADMIN_AFFILIATION_NET,
  SUPPLIER_AFFILIATION_NET,
  AFFILIATION_TAX,
  AdminTypes,
  SupplierTypes,
} from '../../../lib/constants';
import { applyPercentage } from '../../../lib/helpers/financialMath';
import {
  getLastDateOfCurrentYear,
  getMonthsUntilYearEnd,
} from '../../../lib/helpers/date';
import ModalInvoiceDetail from '../../../commons/ModalInvoiceDetail';
import currency from 'currency.js';
import FormErrorMessage from '../../../commons/FormErrorMessage';
import useForm from 'react-hook-form';
import plusTrash from '../../../images/icn-trash.svg';

const INITIAL_STATE = {
  _id: '',
  invoiceNumber: '',
  startDate: '',
  endDate: getLastDateOfCurrentYear(),
  admin: {
    id: '',
    name: '',
    startDate: '',
    endDate: '',
  },
  commercial: {
    id: '',
    name: '',
    startDate: '',
    endDate: '',
    percSuppliers: 0,
    percEnergy: 0,
    percInsurance: 0,
  },
  commercial2: {
    id: '',
    name: '',
    startDate: '',
    endDate: '',
    percSuppliers: 0,
    percEnergy: 0,
    percInsurance: 0,
  },
  payments: [],
};

const AffiliationsPresentational = ({
  getAdmin5Profiles,
  getCommercialProfiles,
  affiliations,
  profiles,
  createAffiliation,
  updateAffiliation,
  renewAffiliation,
  invoiceAffiliation,
  deleteAffiliation,
  deletePayment,
  onStatusChanged,
  setCommercialsSelected,
  ...props
}) => {
  const [t] = useTranslation('CONTACTS');
  const formRef = useRef(null);

  const [activeAffiliationId, setActiveAffiliationId] = useState(
    INITIAL_STATE._id
  );
  const [invoiceNumber, setInvoiceNumber] = useState(
    INITIAL_STATE.invoiceNumber
  );
  const [startDate, setStartDate] = useState(INITIAL_STATE.startDate);
  const [startDateError, setStartDateError] = useState(false);
  const [endDate, setEndDate] = useState(INITIAL_STATE.endDate.toDate());
  const [totalNet, setTotalNet] = useState(0);
  const [totalGross, setTotalGross] = useState(0);
  const [pinvInvoice, setPinvInvoice] = useState([]);
  const [admin, setAdmin] = useState(INITIAL_STATE.admin);
  const [commercial, setCommercial] = useState(INITIAL_STATE.commercial);
  const [commercial2, setCommercial2] = useState(INITIAL_STATE.commercial2);
  const [payments, setPayments] = useState(INITIAL_STATE.payments);
  const activeAffiliation = affiliations[0] || {};
  const [promoMonths, setPromoMonths] = useState([]);
  const [activeAffiliationYear, setActiveAffiliationYear] = useState(false);

  const initialPromoRow = {
    startDate: '',
    endDate: endDate,
  };
  const [promos, setPromos] = useState([]);
  const [newPromo, setNewPromo] = useState(initialPromoRow);

  const [percErrors, setPercErrors] = useState({
    eonError: false,
    insError: false,
    suppError: false,
  });

  const [commercialErrors, setCommercialErrors] = useState([
    {
      maxPercSupp: false,
      maxPercIns: false,
      maxPercEon: false,
    },
    {
      maxPercSupp: false,
      maxPercIns: false,
      maxPercEon: false,
    },
  ]);

  const { register, errors, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues: commercial,
  });

  const [modalState, setModalState] = useState({
    isOpen: false,
    selectedId: null,
  });

  const getMonthsUntil = (endDate, startDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const months =
      (end.getFullYear() - start.getFullYear()) * 12 +
      (end.getMonth() - start.getMonth()) +
      1;

    return months > 0 ? months : 0;
  };

  useEffect(() => {
    let affiliationNet = 0;

    if ([...AdminTypes, 'AGENCY'].includes(props.profile.type))
      affiliationNet = ADMIN_AFFILIATION_NET;

    if (SupplierTypes.includes(props.profile.type))
      affiliationNet = SUPPLIER_AFFILIATION_NET;

    let endDate = new Date();
    if (activeAffiliation._id) {
      endDate = new Date(activeAffiliation.endDate);
    } else {
      endDate.setFullYear(new Date().getFullYear(), 11, 31);
    }
    // const endDate = new Date();
    // endDate.setFullYear(new Date().getFullYear(), 11, 31);

    if (promoMonths.length > 0) {
      setTotalNet(
        currency((affiliationNet / 12) * getMonthsUntil(endDate, startDate))
          .value - currency((affiliationNet / 12) * promoMonths.length)
      );
    } else {
      setTotalNet(
        currency((affiliationNet / 12) * getMonthsUntil(endDate, startDate))
          .value
      );
    }
  }, [startDate, promoMonths]);

  useEffect(() => {
    const updatedPromoMonths = new Set();

    for (const promo of promos) {
      const initialDate = new Date(promo.startDate);
      const endDate = new Date(promo.endDate);

      let startMonth = initialDate.getMonth();
      let endMonth = endDate.getMonth();

      for (let month = startMonth; month <= endMonth; month++) {
        updatedPromoMonths.add(month);
      }
    }

    setPromoMonths(Array.from(updatedPromoMonths).sort((a, b) => a - b));
  }, [promos]);

  useEffect(() => {
    setTotalGross(
      currency(totalNet + applyPercentage(totalNet, AFFILIATION_TAX)).value
    );
  }, [totalNet]);

  const [dateErrors, setDateErrors] = useState({
    startDate: false,
    endDate: false,
    startDateNotInserted: false,
    startDateGreater: false,
    startDateSmaller: false,
    endDateNotInserted: false,
    endDateGreater: false,
    endDateSmaller: false,
  });

  useEffect(() => {
    const {
      _id,
      invoiceNumber,
      startDate,
      endDate,
      admin,
      commercial,
      commercial2,
      payments,
      pinvInvoice,
      promos,
    } = activeAffiliation;

    setActiveAffiliationId(_id || INITIAL_STATE._id);
    setInvoiceNumber(invoiceNumber || INITIAL_STATE.invoiceNumber);
    setStartDate(startDate || INITIAL_STATE.startDate);
    setEndDate(endDate || INITIAL_STATE.endDate);
    setAdmin(admin || INITIAL_STATE.admin);
    setCommercial(commercial || INITIAL_STATE.commercial);
    setCommercial2(commercial2 || INITIAL_STATE.commercial2);
    setPayments(payments || INITIAL_STATE.payments);
    setPinvInvoice(pinvInvoice || []);
    setPromos(promos && promos.length > 0 ? promos : []);
  }, [affiliations]);

  function handleAffiliationSubmit() {
    if (!startDate) {
      setStartDateError(true);
      return;
    }

    const data = {
      profileId: props.id,
      invoiceNumber,
      startDate,
      endDate,
      totalGross,
      totalTax: AFFILIATION_TAX,
      totalNet,
      admin,
      commercial,
      commercial2,
      payments,
      promos,
    };

    setCommercialErrors([
      {
        maxPercSupp: false,
        maxPercIns: false,
        maxPercEon: false,
      },
      {
        maxPercSupp: false,
        maxPercIns: false,
        maxPercEon: false,
      },
    ]);

    const newCommercialErrors = [
      {
        maxPercSupp: false,
        maxPercIns: false,
        maxPercEon: false,
      },
      {
        maxPercSupp: false,
        maxPercIns: false,
        maxPercEon: false,
      },
    ];

    if (commercial?.percEnergy > 5) newCommercialErrors[0].maxPercEon = true;
    if (commercial?.percInsurance > 5) newCommercialErrors[0].maxPercIns = true;
    if (commercial?.percSuppliers > 5)
      newCommercialErrors[0].maxPercSupp = true;
    if (commercial2?.percEnergy > 5) newCommercialErrors[1].maxPercEon = true;
    if (commercial2?.percInsurance > 5)
      newCommercialErrors[1].maxPercIns = true;
    if (commercial2?.percSuppliers > 5)
      newCommercialErrors[1].maxPercSupp = true;

    setCommercialErrors(newCommercialErrors);

    const hasAnyTrue = newCommercialErrors.some(
      (error) => error.maxPercSupp || error.maxPercIns || error.maxPercEon
    );

    if (hasAnyTrue) {
      return;
    }

    Number(commercial?.percEnergy) + Number(commercial2?.percEnergy) > 5
      ? (percErrors.eonError = true)
      : (percErrors.eonError = false);
    Number(commercial?.percInsurance) + Number(commercial2?.percInsurance) > 5
      ? (percErrors.insError = true)
      : (percErrors.insError = false);
    Number(commercial?.percSuppliers) + Number(commercial2?.percSuppliers) > 5
      ? (percErrors.suppError = true)
      : (percErrors.suppError = false);

    if (
      percErrors.eonError === true ||
      percErrors.insError === true ||
      percErrors.suppError === true
    ) {
      console.log('Errrore nella somma delle percentuali!');
      return;
    }

    activeAffiliationId
      ? updateAffiliation({ id: activeAffiliationId, data })
      : createAffiliation(data);
  }

  function handleRenew() {
    renewAffiliation({ id: activeAffiliationId });
  }

  const handleDeleteAffiliation = () => {
    deleteAffiliation(activeAffiliationId);
  };

  const handleNewRow = () => {
    const error = {};

    const rowStartDate = moment(newPromo.startDate);
    const rowEndDate = moment(newPromo.endDate);
    const momentEndDate = moment(new Date(endDate));

    if (startDate === '') {
      error.startDateNotInserted = true;
    } else if (newPromo.startDate === '') {
      error.startDate = true;
    } else if (rowStartDate.isAfter(momentEndDate, 'day')) {
      error.startDateGreater = true;
    } else if (rowStartDate.isBefore(startDate, 'day')) {
      error.startDateSmaller = true;
    }

    if (endDate === '') {
      error.endDateNotInserted = true;
    } else if (newPromo.endDate === '') {
      error.endDate = true;
    } else if (rowEndDate.isAfter(momentEndDate, 'day')) {
      error.endDateGreater = true;
    } else if (rowEndDate.isBefore(startDate, 'day')) {
      error.endDateSmaller = true;
    }

    setDateErrors({ ...dateErrors, ...error });

    if (
      error.startDate === true ||
      error.endDate === true ||
      error.startDateNotInserted === true ||
      error.startDateGreater === true ||
      error.startDateSmaller === true ||
      error.endDateNotInserted === true ||
      error.endDateGreater === true ||
      error.endDateSmaller === true
    ) {
      return;
    }
    promos.push(newPromo);
    setPromos([...promos, newPromo]);
    setNewPromo(initialPromoRow);
    setDateErrors({
      startDate: false,
      endDate: false,
      startDateNotInserted: false,
      startDateGreater: false,
      startDateSmaller: false,
      endDateNotInserted: false,
      endDateGreater: false,
      endDateSmaller: false,
    });
    handleSubmit(handleAffiliationSubmit());
  };

  const handleRemoveRow = (index) => {
    promos.splice(index, 1);
    setPromos([...promos]);

    let affiliationNet = 0;

    if (AdminTypes.includes(props.profile.type))
      affiliationNet = ADMIN_AFFILIATION_NET;

    if (SupplierTypes.includes(props.profile.type))
      affiliationNet = SUPPLIER_AFFILIATION_NET;

    setTotalNet(
      currency((affiliationNet / 12) * getMonthsUntilYearEnd(startDate)).value
    );

    handleSubmit(handleAffiliationSubmit());
  };

  const handleInsertDate = (e) => {
    setNewPromo({ ...newPromo, startDate: new Date(e) });
    setDateErrors({
      startDate: false,
      // endDate: false,
    });
  };

  const handleInsertEndDate = (e) => {
    setNewPromo({ ...newPromo, endDate: new Date(e) });
    setDateErrors({
      // startDate: false,
      endDate: false,
    });
  };

  return (
    <Form onSubmit={(e) => e.preventDefault()}>
      <TabContent activeTab={'1'}>
        <TabPane tabId="1">
          <ContAffiliation ref={formRef}>
            <Row>
              <Col sm="12" md="6">
                <H5Styled>{t('DATA_AFFILIATION_TITLE')}</H5Styled>
              </Col>
              {activeAffiliationId && (
                <Col sm="12" md="6" className="text-right">
                  <ContFlexRight>
                    {!pinvInvoice.length > 0 && (
                      <ModalConfirm
                        onConfirm={() => {
                          invoiceAffiliation(
                            activeAffiliationId,
                            totalNet.toFixed(2)
                          );
                        }}
                        text={t('CONFIRM_INVOICE')}
                      >
                        <BtnOutlineGreen type="button" className="uppercase">
                          {t('INVOICE_AFFILIATION')}
                        </BtnOutlineGreen>
                      </ModalConfirm>
                    )}
                    {pinvInvoice.length > 0 && (
                      <BtnOutlineGreen
                        type="button"
                        className="uppercase"
                        onClick={() =>
                          setModalState({
                            isOpen: true,
                            selectedId: pinvInvoice[0]._id,
                          })
                        }
                      >
                        {pinvInvoice[0]?.invoicePinv?.status === 'to_deliver'
                          ? t('GOTO_INVOICE_DRAFT_AFFILIATION')
                          : t('GOTO_INVOICE_AFFILIATION')}
                      </BtnOutlineGreen>
                    )}
                    <ModalConfirm
                      onConfirm={handleRenew}
                      text={t('CONFIRM_RENEW')}
                    >
                      <BtnOutlineOrange type="button" className="uppercase">
                        <IconRenewed fill={variables.primary} />
                        {t('RENEWED_AFFILIATION')}
                      </BtnOutlineOrange>
                    </ModalConfirm>
                    {pinvInvoice[0]?.invoicePinv?.status !== 'delivered' && (
                      <ModalConfirm
                        onConfirm={handleDeleteAffiliation}
                        text={t('CONFIRM_DELETE')}
                      >
                        <BtnOutlineRed type="button" className="uppercase">
                          {t('DELETE_AFFILIATION')}
                        </BtnOutlineRed>
                      </ModalConfirm>
                    )}
                  </ContFlexRight>
                </Col>
              )}
            </Row>
            <br />
            <Row>
              <Col sm="12" md="6" lg="2">
                <FormGroup>
                  <Label>{t('INVOICE_NUMBER')}</Label>
                  <Input
                    type="text"
                    name="invoiceNumber"
                    value={
                      pinvInvoice[0]?.invoicePinv?.status === 'to_deliver'
                        ? ''
                        : pinvInvoice[0]?.invoicePinv?.fullnumber
                    }
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="2">
                <FormGroup>
                  <Label>{t('AFFILIATION_START')} *</Label>
                  <DateInput
                    value={startDate}
                    setMidday
                    onChange={(value) => {
                      let startDateYear = new Date(value).getFullYear();
                      let endDateUpdate = new Date(endDate);
                      setEndDate(
                        moment(endDateUpdate.setFullYear(startDateYear)).format(
                          'YYYY-MM-DD'
                        )
                      );
                      setStartDate(value);
                    }}
                    readOnly={activeAffiliation.pinvInvoice?.length > 0}
                  />
                  {startDateError && (
                    <FormText className="error-message">
                      {t('COMMON:REQUIRED')}
                    </FormText>
                  )}
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="2">
                <FormGroup>
                  <Label>{t('AFFILIATION_END')}</Label>
                  <DateInput
                    value={endDate}
                    setMidday
                    onChange={setEndDate}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="2">
                <FormGroup>
                  <Label>{t('IMPORT_NET')}</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      name="totalNet"
                      placeholder="0.00"
                      value={totalNet.toFixed(2)}
                      readOnly
                    />
                    <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="2">
                <FormGroup>
                  <Label>{t('TAX_PERCENTAGE')}</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      name="AFFILIATION_TAX"
                      placeholder="0.00"
                      value={AFFILIATION_TAX.toFixed(2)}
                      readOnly
                    />
                    <InputGroupAddon addonType="prepend">%</InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col sm="12" md="6" lg="2">
                <FormGroup>
                  <Label>{t('IMPORT_GROSS')}</Label>
                  <InputGroup>
                    <Input
                      type="number"
                      name="totalGross"
                      value={totalGross.toFixed(2)}
                      readOnly
                    />
                    <InputGroupAddon addonType="prepend">€</InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            {!SupplierTypes.includes(props.profile.type) && (
              <>
                <Row>
                  <Col sm="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('AREA_MANAGER')}</Label>
                      <AutoSuggest
                        onFetch={getCommercialProfiles}
                        placeholder="Cerca un responsabile di zona..."
                        renderSuggestion={({ businessName }) => businessName}
                        getSuggestionValue={(suggestion) =>
                          suggestion.businessName
                        }
                        onSelect={(suggestion) =>
                          setCommercial({
                            ...commercial,
                            id: suggestion._id,
                            name: suggestion.businessName,
                          })
                        }
                        value={commercial.name}
                        onChange={(value) => {
                          if (value === '') {
                            setCommercial(INITIAL_STATE.commercial);
                          } else {
                            setCommercial({ ...commercial, name: value });
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="2">
                    <FormGroup>
                      <Label>{t('START_DATE')}</Label>
                      <DateInput
                        value={commercial.startDate}
                        setMidday
                        onChange={(value) =>
                          setCommercial({ ...commercial, startDate: value })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="2">
                    <FormGroup>
                      <Label>{t('END_DATE')}</Label>
                      <DateInput
                        value={commercial.endDate}
                        setMidday
                        onChange={(value) =>
                          setCommercial({ ...commercial, endDate: value })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="1">
                    <FormGroup>
                      <Label>% Fornitori</Label>
                      <Input
                        type="number"
                        name="percSuppliers"
                        value={commercial.percSuppliers}
                        onChange={(e) => {
                          setCommercial({
                            ...commercial,
                            percSuppliers: e.target.value,
                          });
                          setPercErrors({
                            eonError: false,
                            insError: false,
                            suppError: false,
                          });
                        }}
                        innerRef={register}
                      />
                      {commercialErrors[0].maxPercSupp && (
                        <FormText className="error-message">
                          {t('ERROR_AFFILIATION_PERC_MAX')}
                        </FormText>
                      )}
                      {percErrors.suppError && (
                        <FormText className="error-message">
                          La somma delle percentuali fornitori é maggiore di 5
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="1">
                    <FormGroup>
                      <Label>% Energia</Label>
                      <Input
                        type="number"
                        name="percEnergy"
                        value={commercial.percEnergy}
                        onChange={(e) => {
                          setCommercial({
                            ...commercial,
                            percEnergy: e.target.value,
                          });
                          setPercErrors({
                            eonError: false,
                            insError: false,
                            suppError: false,
                          });
                        }}
                        innerRef={register}
                      />
                      <FormErrorMessage errors={errors} name="percEnergy" />
                      {commercialErrors[0].maxPercEon && (
                        <FormText className="error-message">
                          {t('ERROR_AFFILIATION_PERC_MAX')}
                        </FormText>
                      )}
                      {percErrors.eonError && (
                        <FormText className="error-message">
                          La somma delle percentuali energia é maggiore di 5
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="1">
                    <FormGroup>
                      <Label>% Ass.</Label>
                      <Input
                        type="number"
                        name="percInsurance"
                        value={commercial.percInsurance}
                        onChange={(e) => {
                          setCommercial({
                            ...commercial,
                            percInsurance: e.target.value,
                          });
                          setPercErrors({
                            eonError: false,
                            insError: false,
                            suppError: false,
                          });
                        }}
                        innerRef={register}
                      />
                      {commercialErrors[0].maxPercIns && (
                        <FormText className="error-message">
                          {t('ERROR_AFFILIATION_PERC_MAX')}
                        </FormText>
                      )}
                      {percErrors.insError && (
                        <FormText className="error-message">
                          La somma delle percentuali assicurazioni é maggiore di
                          5
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col sm="12" md="6" lg="4">
                    <FormGroup>
                      <Label>{t('COMMERCIAL')}</Label>
                      <AutoSuggest
                        onFetch={getCommercialProfiles}
                        placeholder="Cerca un commerciale..."
                        renderSuggestion={({ businessName }) => businessName}
                        getSuggestionValue={(suggestion) =>
                          suggestion.businessName
                        }
                        onSelect={(suggestion) => {
                          setCommercial2({
                            ...commercial2,
                            id: suggestion._id,
                            name: suggestion.businessName,
                          });
                        }}
                        value={commercial2.name}
                        onChange={(value) => {
                          if (value === '') {
                            setCommercial2(INITIAL_STATE.commercial2);
                          } else {
                            setCommercial2({ ...commercial2, name: value });
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="2">
                    <FormGroup>
                      <Label>{t('START_DATE')}</Label>
                      <DateInput
                        value={commercial2.startDate}
                        setMidday
                        onChange={(value) =>
                          setCommercial2({ ...commercial2, startDate: value })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="2">
                    <FormGroup>
                      <Label>{t('END_DATE')}</Label>
                      <DateInput
                        value={commercial2.endDate}
                        setMidday
                        onChange={(value) =>
                          setCommercial2({ ...commercial2, endDate: value })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="1">
                    <FormGroup>
                      <Label>% Fornitori</Label>
                      <Input
                        type="number"
                        name="percSuppliers"
                        value={commercial2.percSuppliers}
                        onChange={(e) => {
                          setCommercial2({
                            ...commercial2,
                            percSuppliers: e.target.value,
                          });
                          setPercErrors({
                            eonError: false,
                            insError: false,
                            suppError: false,
                          });
                        }}
                        innerRef={register}
                      />
                      {commercialErrors[1].maxPercSupp && (
                        <FormText className="error-message">
                          {t('ERROR_AFFILIATION_PERC_MAX')}
                        </FormText>
                      )}
                      {percErrors.suppError && (
                        <FormText className="error-message">
                          La somma delle percentuali fornitori é maggiore di 5
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="1">
                    <FormGroup>
                      <Label>% Energia</Label>
                      <Input
                        type="number"
                        name="percEnergy"
                        value={commercial2.percEnergy}
                        onChange={(e) => {
                          setCommercial2({
                            ...commercial2,
                            percEnergy: e.target.value,
                          });
                          setPercErrors({
                            eonError: false,
                            insError: false,
                            suppError: false,
                          });
                        }}
                        innerRef={register}
                      />
                      {commercialErrors[1].maxPercEon && (
                        <FormText className="error-message">
                          {t('ERROR_AFFILIATION_PERC_MAX')}
                        </FormText>
                      )}
                      {percErrors.eonError && (
                        <FormText className="error-message">
                          La somma delle percentuali energia é maggiore di 5
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="12" md="6" lg="1">
                    <FormGroup>
                      <Label>% Ass.</Label>
                      <Input
                        type="number"
                        name="percInsurance"
                        value={commercial2.percInsurance}
                        onChange={(e) => {
                          setCommercial2({
                            ...commercial2,
                            percInsurance: e.target.value,
                          });
                          setPercErrors({
                            eonError: false,
                            insError: false,
                            suppError: false,
                          });
                        }}
                        innerRef={register}
                      />
                      {commercialErrors[1].maxPercIns && (
                        <FormText className="error-message">
                          {t('ERROR_AFFILIATION_PERC_MAX')}
                        </FormText>
                      )}
                      {percErrors.insError && (
                        <FormText className="error-message">
                          La somma delle percentuali assicurazioni é maggiore di
                          5
                        </FormText>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )}
            <br />

            <div className="m-tb-20">
              <TabContent activeTab={'1'}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '50%',
                    }}
                  >
                    <H5Styled style={{ margin: '0' }}>
                      {activeAffiliation.pinvInvoice?.length > 0
                        ? promos.length > 0
                          ? 'Promozioni'
                          : ''
                        : 'Aggiungi nuova promozione'}
                    </H5Styled>
                  </div>
                </div>
                <TabPane tabId="1">
                  {!(activeAffiliation.pinvInvoice?.length > 0) && (
                    <>
                      <Row>
                        <Col sm="12" md="2" lg="2">
                          <FormGroup>
                            <Label>{t('START_DATE')}</Label>
                            <DateInput
                              name="startDate"
                              value={newPromo.startDate}
                              onChange={(e) => handleInsertDate(e)}
                              innerRef={register}
                            />
                            {dateErrors.startDate && (
                              <FormText className="error-message">
                                {t('START_DATE_ERROR')}
                              </FormText>
                            )}
                            {dateErrors.startDateNotInserted && (
                              <FormText className="error-message">
                                {t('START_DATE_NOT_INSERTED')}
                              </FormText>
                            )}
                            {dateErrors.startDateGreater && (
                              <FormText className="error-message">
                                {t('START_DATE_GREATER')}
                              </FormText>
                            )}
                            {dateErrors.startDateSmaller && (
                              <FormText className="error-message">
                                {t('START_DATE_SMALLER')}
                              </FormText>
                            )}
                          </FormGroup>
                        </Col>

                        <Col sm="12" md="2" lg="2">
                          <FormGroup>
                            <Label>{t('END_DATE')}</Label>
                            <DateInput
                              name="endDate"
                              onChange={(e) => handleInsertEndDate(e)}
                              value={newPromo.endDate}
                              innerRef={register}
                            />
                            {dateErrors.endDate && (
                              <FormText className="error-message">
                                {t('PROMO:END_DATE_ERROR')}
                              </FormText>
                            )}
                            {dateErrors.endDateNotInserted && (
                              <FormText className="error-message">
                                {t('END_DATE_NOT_INSERTED')}
                              </FormText>
                            )}
                            {dateErrors.endDateGreater && (
                              <FormText className="error-message">
                                {t('END_DATE_GREATER')}
                              </FormText>
                            )}
                            {dateErrors.endDateSmaller && (
                              <FormText className="error-message">
                                {t('END_DATE_SMALLER')}
                              </FormText>
                            )}
                          </FormGroup>
                        </Col>

                        <Col sm="12" md="2" lg="2">
                          <ButtonGroup className="mt-4">
                            <BtnRadiusRed type="button" onClick={handleNewRow}>
                              <IconPlus />
                            </BtnRadiusRed>
                          </ButtonGroup>
                        </Col>
                      </Row>
                      <hr />
                    </>
                  )}

                  {promos.length > 0 ? (
                    promos?.map((row, index) => (
                      <Row key={index}>
                        <Col sm="12" md="2" lg="2">
                          <FormGroup>
                            <Label>{t('START_DATE')}</Label>
                            <DateInput
                              name="startDate"
                              value={promos[index].startDate}
                              innerRef={register}
                              readOnly
                            />
                            {dateErrors[index]?.startDate && (
                              <FormText className="error-message">
                                {t('START_DATE_ERROR')}
                              </FormText>
                            )}
                          </FormGroup>
                        </Col>

                        <Col sm="12" md="2" lg="2">
                          <FormGroup>
                            <Label>{t('END_DATE')}</Label>
                            <DateInput
                              name="endDate"
                              value={promos[index].endDate}
                              innerRef={register}
                              readOnly
                            />
                            {dateErrors[index]?.endDate && (
                              <FormText className="error-message">
                                {t('END_DATE_ERROR')}
                              </FormText>
                            )}
                          </FormGroup>
                        </Col>

                        {!(activeAffiliation.pinvInvoice?.length > 0) && (
                          <Col sm="12" md="2" lg="2">
                            <ButtonGroup className="mt-4">
                              <BtnRadiusRed type="button" className="mr-3">
                                <img
                                  src={plusTrash}
                                  alt="Elimina"
                                  onClick={() => handleRemoveRow(index)}
                                />
                              </BtnRadiusRed>
                            </ButtonGroup>
                          </Col>
                        )}
                      </Row>
                    ))
                  ) : (
                    <H4Styled style={{ marginTop: '15px' }}>
                      Non ci sono promozioni per questa affiliazione!
                    </H4Styled>
                  )}
                </TabPane>
              </TabContent>
            </div>

            <ContFlexRight>
              <BtnOutlineGreen
                type="button"
                className="uppercase"
                onClick={handleSubmit(handleAffiliationSubmit)}
              >
                <IconPlus />
                {t('ADD_AFFILIATION')}
              </BtnOutlineGreen>
            </ContFlexRight>
          </ContAffiliation>

          {affiliations.map((affiliation, index) => {
            if (index !== 0) {
              return (
                <Affiliation
                  key={affiliation._id}
                  affiliation={affiliation}
                  updateAffiliation={updateAffiliation}
                  deleteAffiliation={deleteAffiliation}
                  deletePayment={deletePayment}
                  profile={props.profile}
                  invoiceAffiliation={invoiceAffiliation}
                  showInvoicePinv={(id) =>
                    setModalState({ isOpen: true, selectedId: id })
                  }
                />
              );
            }

            return null;
          })}

          <ModalInvoiceDetail
            modalState={modalState}
            setModalState={setModalState}
            onStatusChanged={onStatusChanged}
          />
        </TabPane>
      </TabContent>
    </Form>
  );
};

export default AffiliationsPresentational;
