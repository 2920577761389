import React, { useState } from 'react';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import { ReactComponent as IcnDocument } from '../../images/icn_document.svg';
import { ReactComponent as IconBack } from '../../images/icn-back.svg';
import {
  Button,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import {
  BtnOutlineGreen,
  BtnOutlineRed,
  BtnRadiusWhite,
  ContFlexLeft,
  H4Styled,
} from '../../styled-components';
import variables from '../../variables';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AgreementsModelingPresentational = ({
  profile,
  agreement,
  selectedModels,
  handleSelectedModels,
  handleSave,
  handleSaveCompilation,
}) => {
  const [t] = useTranslation('AGREEMENTS');

  const [modalDraft, setModalDraft] = useState(false);
  const [modalCompilation, setModalCompilation] = useState(false);

  const toggleUpdateDraft = () => {
    setModalDraft(!modalDraft);
  };

  const toggleCompilation = () => {
    setModalCompilation(!modalCompilation);
  };

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:AGREEMENTS')}
            icon={<IcnDocument fill="#ffffff" style={{ height: '25px' }} />}
          />
        </section>

        <Row style={{ marginBottom: '10px' }}>
          <Col sm="12" md="6">
            <ContFlexLeft>
              <Link to={`/agreements`}>
                <BtnRadiusWhite>
                  <IconBack fill={variables.primary} />
                </BtnRadiusWhite>
              </Link>
              <H4Styled>{t('TITLE_DETAIL')}</H4Styled>
            </ContFlexLeft>
          </Col>
        </Row>

        <Row style={{ marginBottom: '10px' }}>
          <Col
            sm="12"
            md="3"
            style={{
              color: `${variables.primary}`,
              fontSize: '25px',
              fontWeight: 'bold',
            }}
          >
            {agreement.name}
          </Col>
        </Row>

        <Row style={{ marginBottom: '10px', justifyContent: 'end' }}>
          <Col sm="12" md="3">
            <Button
              color="primary"
              style={{
                margin: 0,
                marginRight: '5px',
                fontSize: '1rem',
                width: '100%',
              }}
              onClick={(event) => {
                event.stopPropagation();
                toggleUpdateDraft();
              }}
            >
              {t('UPDATE')}
            </Button>
          </Col>

          <Col sm="12" md="5">
            <Button
              color="primary"
              style={{
                margin: 0,
                fontSize: '1rem',
                width: '100%',
              }}
              onClick={(event) => {
                event.stopPropagation();
                toggleCompilation();
              }}
            >
              {t('SAVE_CONTRACT')}
            </Button>
          </Col>
        </Row>

        <section style={{ backgroundColor: 'white', padding: '3rem' }}>
          {selectedModels.map((model, index) => (
            <Row key={index} style={{ marginBottom: '1rem' }}>
              <Col sm="1" style={{ borderRight: '2px solid black' }}>
                <input
                  type="checkbox"
                  onChange={(e) => {
                    e.stopPropagation();
                    handleSelectedModels(model._id);
                  }}
                  defaultChecked={
                    model.needed ? true : model.checked ? true : false
                  }
                  disabled={model.needed}
                />
              </Col>
              <Col sm="11">
                <div dangerouslySetInnerHTML={{ __html: model.content }} />
              </Col>
            </Row>
          ))}
        </section>

        <Modal isOpen={modalDraft} toggle={toggleUpdateDraft}>
          <ModalHeader toggle={toggleUpdateDraft}>
            {t('MODAL_DRAFT')}
          </ModalHeader>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSave(toggleUpdateDraft);
            }}
          >
            <ModalBody>
              <div className="max-width-form" style={{ textAlign: 'center' }}>
                Sei sicuro di voler salvare le modifiche?
              </div>
            </ModalBody>
            <ModalFooter>
              <BtnOutlineRed
                type="button"
                className="uppercase"
                onClick={toggleUpdateDraft}
              >
                {t('COMMON:CANCEL')}
              </BtnOutlineRed>
              <BtnOutlineGreen type="submit" className="uppercase">
                {t('COMMON:CONFIRM')}
              </BtnOutlineGreen>
            </ModalFooter>
          </Form>
        </Modal>

        <Modal isOpen={modalCompilation} toggle={toggleCompilation}>
          <ModalHeader toggle={toggleCompilation}>
            {t('MODAL_COMPILATION')}
          </ModalHeader>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSaveCompilation(toggleCompilation);
            }}
          >
            <ModalBody>
              <div className="max-width-form" style={{ textAlign: 'center' }}>
                Sei sicuro di voler salvare questa bozza e andare in
                compilazione?
              </div>
            </ModalBody>
            <ModalFooter>
              <BtnOutlineRed
                type="button"
                className="uppercase"
                onClick={toggleCompilation}
              >
                {t('COMMON:CANCEL')}
              </BtnOutlineRed>
              <BtnOutlineGreen type="submit" className="uppercase">
                {t('COMMON:CONFIRM')}
              </BtnOutlineGreen>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default AgreementsModelingPresentational;
