import React from 'react';
import Badge from '../../../commons/DataTableFields/Badge';

const DisponibilityField = ({ value, data, ...props }) => {
  return (
    <div className={data._id ? 'master-yes' : 'master-no'}>
      <Badge>
        {data._id ? 'Documento Caricato' : 'Documento Non Caricato'}
      </Badge>
    </div>
  );
};

export default DisponibilityField;
