import React from 'react';
import { Badge } from 'reactstrap';

const BadgeField = ({ value, data, ...props }) => (
  <div
    className={
      value !== '-' && value === 'DRAFT'
        ? 'badge-draft'
        : value === 'COMPILATION'
        ? 'badge-compilation'
        : value === 'FINISHED'
        ? 'badge-sign-req'
        : 'badge-signed'
    }
    {...props}
  >
    {value && value === 'DRAFT' ? (
      <Badge className="badge-draft">Bozza</Badge>
    ) : value === 'COMPILATION' ? (
      <Badge className="badge-compilation">Compilazione</Badge>
    ) : value === 'FINISHED' ? (
      <Badge className="badge-sign-req">Terminato</Badge>
    ) : value === 'SIGNED' ? (
      <Badge className="badge-signed">Firmato</Badge>
    ) : (
      <div>-</div>
    )}
  </div>
);

export default BadgeField;
