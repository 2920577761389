import React, { useEffect, useState } from 'react';
import { useAuth } from '../../commons/Auth';
import { ReactComponent as IconLogout } from '../../images/icn_logout.svg';
import { ReactComponent as IconEmail } from '../../images/icn-email.svg';
import './Header.scss';
import variables from '../../variables';
import { useTranslation } from 'react-i18next';
import { ADMIN_TYPES } from '../../App';
import { profileScoreFormatter } from '../../lib/helpers/profileScoreFormatter';
import {
  Badge,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { useLoading } from '../Loading';
import { useAlert } from 'react-alert';
import axios from 'axios';
import { Link } from 'react-router-dom';

function Header({ title, icon }) {
  const [, dispatch] = useAuth();
  const [t] = useTranslation('CONTACTS');
  const [{ token, profile, isAdmin }] = useAuth();
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();
  let totalProfileScore = 0;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationsNotReaded, setNotificationsNotReaded] = useState([]);

  const fetchNotifications = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/notifications`,
      {
        headers: { Authorization: token },
        params: {
          limit: 10,
          offset: 0,
        },
      }
    );

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [] } = response;

    setNotifications(data.notifications);

    setNotificationsNotReaded(
      data.notifications.filter((notification) => notification.status === false)
    );
  };

  const updateNotifications = async () => {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/notifications`,
      {},
      {
        headers: { Authorization: token },
      }
    );

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    fetchNotifications();
  };

  const toggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  if (ADMIN_TYPES.includes(profile?.type)) {
    const profileScore = profileScoreFormatter(
      profile?.profileScore?.[0] || {}
    );

    let totalTrainingCalculatedData = 0;
    for (
      let index = 0;
      index < profileScore?.trainingCourses?.length;
      index++
    ) {
      totalTrainingCalculatedData += Number(
        profileScore?.trainingCourses[index]?.credits
      );
    }
    if (totalTrainingCalculatedData > 100) totalTrainingCalculatedData = 100;

    if (profileScore) {
      totalProfileScore += profileScore.EONScore || 0;
      totalProfileScore += profileScore.insuranceScoreType1 || 0;
      totalProfileScore += profileScore.insuranceScoreType2 || 0;
      totalProfileScore += profileScore.supplierScore || 0;
      totalProfileScore += profileScore.trainingScore
        ? profileScore.trainingScore
        : totalTrainingCalculatedData;
    }
  }

  const logout = () => dispatch({ type: 'logout' });
  const adminLevels = [
    'LIVELLO TRIANGOLO',
    'LIVELLO QUADRATO',
    'LIVELLO PENTAGONO',
    'LIVELLO ESAGONO',
    'LIVELLO OTTAGONO',
  ];
  let adminLevel = '';
  if (profile.type !== undefined) {
    switch (profile.type) {
      case 'BUILDING_ADMIN_1':
        adminLevel = adminLevels[0];
        break;
      case 'BUILDING_ADMIN_2':
        adminLevel = adminLevels[1];
        break;
      case 'BUILDING_ADMIN_3':
        adminLevel = adminLevels[2];
        break;
      case 'BUILDING_ADMIN_4':
        adminLevel = adminLevels[3];
        break;
      case 'BUILDING_ADMIN_5':
        adminLevel = adminLevels[4];
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    if (dropdownOpen) {
      updateNotifications();
    }
  }, [dropdownOpen]);

  return (
    <div className="header-crm">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-8">
          <h2>
            {icon}
            {title}
          </h2>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-4">
          <div className="cont-header-element">
            <button onClick={() => logout()} className="btn btn-logout">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-box-arrow-right"
                viewBox="0 0 16 16"
                style={{ color: `${variables.primary}` }}
              >
                <path
                  fill-rule="evenodd"
                  d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"
                />
                <path
                  fill-rule="evenodd"
                  d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
                />
              </svg>
            </button>
            {!isAdmin && (
              <p>
                <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle
                    color="primary"
                    className="btn btn-logout position-relative btn-notification"
                  >
                    <IconEmail fill={variables.primary} />
                    {notificationsNotReaded.length > 0 && (
                      <Badge
                        color="danger"
                        pill
                        style={{
                          fontSize: '0.75rem',
                          transform: 'translate(50%, -50%)',
                          position: 'absolute',
                          right: 0,
                          top: 0,
                        }}
                      >
                        {notificationsNotReaded.length < 10
                          ? notificationsNotReaded.length
                          : '9+'}
                      </Badge>
                    )}
                  </DropdownToggle>
                  <DropdownMenu right className="dropdown-menu-custom">
                    {notifications.length === 0 ? (
                      <DropdownItem disabled>
                        Non ci sono notifiche
                      </DropdownItem>
                    ) : (
                      notifications.map((notification, index) => {
                        const hasBorder = index < notifications.length - 1;
                        return (
                          <DropdownItem
                            key={notification._id}
                            style={{
                              borderBottom: hasBorder
                                ? '1px solid #d3d3d3'
                                : 'none',
                              width: '380px',
                            }}
                          >
                            <Link
                              to={`/notifications`}
                              style={{
                                textDecoration: 'none',
                                color: 'inherit',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                  }}
                                >
                                  <IconEmail
                                    fill={variables.primary}
                                    style={{ width: '20px' }}
                                  />
                                  <strong>{notification.type}</strong>
                                </div>
                                <small style={{ color: 'gray' }}>
                                  {new Date(
                                    notification.createdAt
                                  ).toLocaleDateString()}
                                </small>
                              </div>
                              <div
                                style={{
                                  margin: 0,
                                  fontSize: '0.9rem',
                                  color: '#666',
                                  textAlign: 'start',
                                  whiteSpace: 'normal',
                                  wordWrap: 'break-word',
                                  overflowWrap: 'break-word',
                                }}
                              >
                                {notification.description}
                              </div>
                            </Link>
                          </DropdownItem>
                        );
                      })
                    )}
                  </DropdownMenu>
                </Dropdown>
              </p>
            )}
            {ADMIN_TYPES.includes(profile.type) && (
              <p className="credits">
                {adminLevel} - CREDITI: {totalProfileScore || 0}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
