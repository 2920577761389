import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../commons/Auth';
import { useAlert } from 'react-alert';
import { useLoading } from '../../../commons/Loading';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import axios from 'axios';
import CondoAgreementsPresentational from './CondoAgreementsPresentational';
import { getSignedUrl } from '../../../lib/fetch/files';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const CondoAgreements = (props) => {
  const [{ token } = {}] = useAuth();
  const [t] = useTranslation();
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();

  const { profile, data: condo } = props;

  const [agreements, setAgreements] = useState([]);
  const [total, setTotal] = useState(0);

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
    name: withDefault(StringParam, ''),
    vatCode: withDefault(StringParam, ''),
    status: withDefault(StringParam, ''),
  });

  const { limit, offset, name, vatCode, status } = query;

  useEffect(() => {
    if (condo._id) {
      fetchAgreements();
    }
  }, [limit, offset, name, vatCode, status]);

  const fetchAgreements = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agreements/condo/${condo._id}`,
      {
        headers: { Authorization: token },
        params: {
          ...query,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers = {} } = response;

    setAgreements(data.agreements);
    setTotal(+headers['x-total-count']);
  };

  function onFilter(queryDiff) {
    if (
      (queryDiff.name && queryDiff.name !== name) || // first search
      (name && queryDiff.name === '') || // search deleted
      (queryDiff.status && queryDiff.status !== status) || // first search
      (status && queryDiff.status === '') || // search deleted
      (queryDiff.vatCode && queryDiff.vatCode !== vatCode) || // first search
      (vatCode && queryDiff.vatCode === '') || // search deleted
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0; // return to first page
    }

    setQuery(queryDiff);
  }

  async function handleFileListView(key) {
    showLoading();

    const { data, error } = await getSignedUrl(key, token);

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    hideLoading();
    window.open(data.url, '_blank');
  }

  return (
    <CondoAgreementsPresentational
      data={agreements}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      profile={profile}
      query={query}
      condo={condo}
      handleFileListView={handleFileListView}
      fetchAgreements={fetchAgreements}
    />
  );
};

export default CondoAgreements;
