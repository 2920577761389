import React, { useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useLoading } from '../../commons/Loading';
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  FormText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import { BtnOutlineGreen, BtnOutlineRed } from '../../styled-components';

const INITAL_FILE_FORM_VALUES = {
  file: '',
};

function PSIModal({ attachments, uploadPSIDocument }) {
  const [t] = useTranslation('AGREEMENTS');
  const alert = useAlert();
  const [{ token, profile = {} } = {}] = useAuth();
  const { showLoading, hideLoading } = useLoading();

  const [addPSIDocumentModal, setAddPSIDocumentModal] = useState(false);
  const [formValues, setFormValues] = useState(INITAL_FILE_FORM_VALUES);
  const [errors, setErrors] = useState({
    file: false,
  });

  const toggleAddPSIDocument = () => {
    setAddPSIDocumentModal(!addPSIDocumentModal);
  };

  function handleFileChange() {
    const file = formValues.file;

    const fileSize = (file.size / 1024 ** 2).toFixed(2);

    if (fileSize > 20) {
      alert.info('File troppo grande. Max 20Mb');
    } else {
      const data = new FormData();
      data.append('file', file);

      errors.file = false;

      if (file === '') errors.file = true;

      const arrayErrors = Object.values(errors);
      if (!arrayErrors.includes(true)) {
        uploadPSIDocument(data, toggleAddPSIDocument);
      }
    }
  }

  return (
    <React.Fragment>
      <Button
        target="_blank"
        color="primary"
        style={{ marginTop: 0, width: '100%' }}
        onClick={(event) => {
          event.stopPropagation();
          toggleAddPSIDocument();
        }}
      >
        Aggiungere documento PSI
      </Button>

      <Modal
        isOpen={addPSIDocumentModal}
        toggle={toggleAddPSIDocument}
        className="new-PSI-modal"
      >
        <ModalHeader toggle={toggleAddPSIDocument}>
          {t('FILES:ADD')}
        </ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleFileChange();
          }}
        >
          <ModalBody>
            <Row>
              <Col sm="12">
                <FormGroup>
                  <Label for="selectFile">
                    {t('FILES:CHOOSE_FILE')}
                    &nbsp;({t('FILES:MAX_UPLOAD_FILE_SIZE')})
                  </Label>
                  <CustomInput
                    type="file"
                    id="filePSI"
                    name="filePSI"
                    onChange={(event) =>
                      setFormValues({
                        ...formValues,
                        file: event.target.files[0],
                      })
                    }
                  />
                  {errors.file === '' && (
                    <FormText className="error-message">
                      {t('FILE_ERROR')}
                    </FormText>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <BtnOutlineRed
              type="button"
              className="uppercase"
              onClick={toggleAddPSIDocument}
            >
              {t('COMMON:CANCEL')}
            </BtnOutlineRed>
            <BtnOutlineGreen type="submit" className="uppercase">
              {t('COMMON:CONFIRM')}
            </BtnOutlineGreen>
          </ModalFooter>
        </Form>
      </Modal>
    </React.Fragment>
  );
}

export default PSIModal;
