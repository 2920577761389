import React from 'react';
import { Col, Row } from 'reactstrap';

const CommentTitle = ({ text, commentId, toggleDeleteComment }) => {
  const truncateText = (text) => {
    return text.length > 30 ? text.substring(0, 30) + '...' : text;
  };

  return (
    <p>
      <Row>
        <Col sm="10">
          <strong>{truncateText(text)}</strong>
        </Col>

        <Col sm="2">
          <div onClick={() => toggleDeleteComment(commentId)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-trash-fill"
              viewBox="0 0 16 16"
              style={{ margin: '0', cursor: 'pointer', color: '#FF5252' }}
            >
              <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0" />
            </svg>
          </div>
        </Col>
      </Row>
    </p>
  );
};

export default CommentTitle;
