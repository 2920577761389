import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BurgerMenu from '../../commons/BurgerMenu';
import Header from '../../commons/Header';
import { ReactComponent as IcnDocument } from '../../images/icn_document.svg';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import {
  BtnOutlineGreen,
  BtnOutlineRed,
  BtnRadiusWhite,
  ContFlexLeft,
  H4Styled,
} from '../../styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as IconBack } from '../../images/icn-back.svg';
import variables from '../../variables';
import CKEditor from 'ckeditor4-react';
import { jsPDF } from 'jspdf';
import { useAlert } from 'react-alert';
import html2pdf from 'html2pdf.js';
import CommentTitle from './CommentTitle';

const MAX_FILE_SIZE = 2 * 1024 * 1024 * 1024;

const AgreementEditorPresentational = ({
  profile,
  agreement,
  handleSave,
  handleSaveRevision,
  handleSaveFirmed,
}) => {
  const [t] = useTranslation('AGREEMENTS');
  const alert = useAlert();

  const [content, setContent] = useState('<p>Scrivi il contenuto qui...</p>');
  const [comments, setComments] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedText, setSelectedText] = useState('');
  const [newComment, setNewComment] = useState('');
  const [textId, setTextId] = useState('');

  const [modalCompilation, setModalCompilation] = useState(false);
  const [modalRevision, setModalRevision] = useState(false);
  const [modalFirmed, setModalFirmed] = useState(false);
  const [deleteCommentId, setDeleteCommentId] = useState('');
  const [modalDeleteComment, setModalDeleteComment] = useState(false);

  const [startEditor, setStartEditor] = useState(false);

  const toggleCompilation = () => {
    setModalCompilation(!modalCompilation);
  };
  const toggleRevision = () => {
    setModalRevision(!modalRevision);
  };

  const toggleUpdateFirmed = () => {
    setModalFirmed(!modalFirmed);
  };

  const toggleDeleteComment = (commentId) => {
    setModalDeleteComment(!modalDeleteComment);
    setDeleteCommentId(commentId);
  };

  const handleEditorChange = (event) => {
    setContent(event.editor.getData());
  };

  const handleCommentAdded = (event) => {
    setComments((prev) => [...prev, event.data]);
  };

  const handleAddComment = (editor) => {
    const selectedText = editor.getSelection().getSelectedText();
    if (selectedText) {
      const uniqueId = 'text-' + Date.now();
      setSelectedText(selectedText);
      setTextId(uniqueId);
      setIsModalOpen(true);
    }
  };

  const handleSaveComment = () => {
    if (newComment) {
      const editor = window.CKEDITOR.instances.editor1;
      const selection = editor.getSelection();
      const range = selection.getRanges()[0];

      if (range) {
        const span = editor.document.createElement('span');
        span.setAttribute('id', textId);
        span.setAttribute('class', 'highlighted-text');
        span.setText(selectedText);

        range.deleteContents();
        range.insertNode(span);

        setContent(editor.getData());
        setComments((prev) => [
          ...prev,
          { text: selectedText, comment: newComment, id: textId },
        ]);

        setIsModalOpen(false);
        setNewComment('');
        handleSave(
          editor.getData(),
          [
            ...comments,
            { text: selectedText, comment: newComment, id: textId },
          ],
          toggle
        );
      }
    }
  };

  const handleDeleteComment = (id) => {
    const editor = window.CKEDITOR.instances.editor1;
    const editorDocument = editor.document;

    const targetElement = editorDocument.getById(id);

    if (targetElement) {
      const textContent = targetElement.getText();

      const textNode = editorDocument.createText(textContent);

      targetElement.insertBeforeMe(textNode);

      targetElement.remove();

      setContent(editor.getData());

      setComments((prev) => prev.filter((comment) => comment.id !== id));
    }

    handleValidateComments(toggleDeleteComment, editor.getData());
  };

  const toggle = () => {
    setNewComment('');
    setSelectedText('');
    setIsModalOpen(!isModalOpen);
  };

  const handleGeneratePDF = () => {
    const element = document.createElement('div');
    element.innerHTML = content;

    const opt = {
      pagebreak: { mode: 'avoid-all' },
      margin: [7, 7, 7, 7],
      filename: 'file.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 4, letterRendering: true },
      jsPDF: {
        unit: 'mm',
        format: 'a4',
        orientation: 'portrait',
      },
    };

    html2pdf()
      .from(element)
      .set(opt)
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        const pdfBlob = pdf.output('blob');

        if (pdfBlob.size > MAX_FILE_SIZE) {
          alert('Il file è troppo grande. La dimensione massima è di 2 GB.');
          return;
        }

        const data = new FormData();
        data.append('file', pdfBlob, `contract-${Date.now()}.pdf`);
        data.append('content', content);
        data.append('comments', JSON.stringify(comments));

        handleSaveFirmed(data, toggleUpdateFirmed);
      });
  };

  useEffect(() => {
    if (agreement._id) {
      let text = '';
      if (agreement.content) {
        text = agreement.content;
      } else {
        for (const model of agreement.draftTeamplate) {
          if (model.needed || model.checked) {
            text = text + model.content + '<br/>';
          }
        }
      }
      setContent(text);
      setStartEditor(true);
      setComments(
        agreement.comments && agreement.comments.length
          ? agreement.comments
          : []
      );
    }
  }, [agreement]);

  const handleValidateComments = (toggle, contentPassed) => {
    const editor = window.CKEDITOR.instances.editor1;
    const editorDocument = editor.document;

    const commentsForValidation = comments.filter((comment) => {
      const element = editorDocument.getById(comment.id);
      return !!element;
    });

    handleSave(
      contentPassed ? contentPassed : content,
      commentsForValidation,
      toggle
    );
  };

  const handleValidateCommentsRevision = (toggle) => {
    const editor = window.CKEDITOR.instances.editor1;
    const editorDocument = editor.document;

    const commentsForValidation = comments.filter((comment) => {
      const element = editorDocument.getById(comment.id);
      return !!element;
    });

    handleSaveRevision(content, commentsForValidation, toggle);
  };

  const getEditorConfig = () => {
    const basePlugins = 'font,colorbutton,justify';
    const shouldIncludeComments =
      agreement && !['FINISHED', 'FIRMED'].includes(agreement.status);

    return {
      extraPlugins: shouldIncludeComments
        ? `${basePlugins},comments`
        : basePlugins,
      allowedContent: true,
      toolbar: [
        ['Bold', 'Italic', 'Underline'],
        ['Font', 'FontSize', 'TextColor', 'BGColor'],
        ['Link', 'Unlink'],
        ['NumberedList', 'BulletedList'],
        ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
        ['Table'],
        ...(shouldIncludeComments ? [['Comment']] : []),
      ],
    };
  };

  return (
    <div className="App">
      <BurgerMenu />

      <div className="corpo">
        <section>
          <Header
            title={t('PAGES:AGREEMENTS')}
            icon={<IcnDocument fill="#ffffff" style={{ height: '25px' }} />}
          />
        </section>

        <Row style={{ marginBottom: '10px' }}>
          <Col sm="12" md="6">
            <ContFlexLeft>
              <Link to={`/agreements`}>
                <BtnRadiusWhite>
                  <IconBack fill={variables.primary} />
                </BtnRadiusWhite>
              </Link>
              <H4Styled>{t('TITLE_DETAIL')}</H4Styled>
            </ContFlexLeft>
          </Col>

          <Col sm="12" md="6" style={{ textAlign: 'end' }}>
            <Button
              color="primary"
              style={{
                margin: 0,
                marginRight: '5px',
                fontSize: '1rem',
                display:
                  agreement.status === 'FINISHED' ||
                  agreement.status === 'FIRMED'
                    ? 'none'
                    : 'unset',
              }}
              onClick={(event) => {
                event.stopPropagation();
                toggleCompilation();
              }}
            >
              {t('UPDATE')}
            </Button>
            <Button
              color="primary"
              style={{
                margin: 0,
                fontSize: '1rem',
                display: agreement.status === 'COMPILATION' ? 'unset' : 'none',
              }}
              onClick={(event) => {
                event.stopPropagation();
                toggleRevision();
              }}
            >
              {t('SAVE_REVISION')}
            </Button>
            <Button
              color="primary"
              style={{
                margin: 0,
                fontSize: '1rem',
                display:
                  profile.type !== 'SUPPLIER' && agreement.status === 'REVISION'
                    ? 'unset'
                    : 'none',
              }}
              onClick={(event) => {
                event.stopPropagation();
                toggleUpdateFirmed();
              }}
            >
              {t('SAVE_FIRMED')}
            </Button>
          </Col>
        </Row>

        <section>
          <div className="editor-container">
            <div className="editor">
              {startEditor && (
                <CKEditor
                  data={content}
                  name={'editor1'}
                  onBeforeLoad={(CKEDITOR) => {
                    const editorName = 'editor1';

                    if (window.CKEDITOR.instances[editorName]) {
                      window.CKEDITOR.instances[editorName].destroy(true);
                    }

                    if (
                      agreement &&
                      !CKEDITOR.plugins.get('comments') &&
                      !['FINISHED', 'FIRMED'].includes(agreement.status)
                    ) {
                      CKEDITOR.plugins.add('comments', {
                        init: function (editor) {
                          editor.ui.addButton('Comment', {
                            label: 'Aggiungi Commento',
                            command: 'addComment',
                            toolbar: 'insert',
                          });
                        },
                      });
                    }
                  }}
                  config={getEditorConfig()}
                  onChange={handleEditorChange}
                  onInstanceReady={(evt) => {
                    const editorInstance = evt.editor;

                    if (profile.type === 'SUPPLIER') {
                      editorInstance.setReadOnly(true);
                    }

                    editorInstance.on('commentAdded', handleCommentAdded);

                    if (
                      agreement &&
                      !['FINISHED', 'FIRMED'].includes(agreement.status)
                    ) {
                      editorInstance.addCommand('addComment', {
                        exec: () => handleAddComment(editorInstance),
                        readOnly: 1,
                      });
                      editorInstance.ui.addButton('Comment', {
                        label: 'Aggiungi Commento',
                        command: 'addComment',
                        toolbar: 'insert',
                      });
                    }
                  }}
                />
              )}
            </div>
            {comments.length > 0 && (
              <div className="comments-bar">
                <div className="comments-title">
                  <H4Styled>Commenti</H4Styled>
                </div>
                <Row>
                  {comments.map((comment, index) => (
                    <Col key={index} className="comment" sm="12">
                      <CommentTitle
                        text={comment.text}
                        commentId={comment.id}
                        toggleDeleteComment={toggleDeleteComment}
                      />
                      <p className="comment-text">{comment.comment}</p>
                      <Button
                        color="primary"
                        style={{
                          margin: 0,
                          fontSize: '1rem',
                        }}
                        onClick={() => {
                          const editorInstance =
                            window.CKEDITOR.instances.editor1;
                          if (editorInstance) {
                            const targetElement = editorInstance.document.getById(
                              comment.id
                            );
                            if (targetElement) {
                              const editorContainer =
                                editorInstance.container.$;
                              const editorScroll = editorContainer.querySelector(
                                '.cke_contents'
                              );

                              if (editorScroll) {
                                targetElement.scrollIntoView({
                                  behavior: 'smooth',
                                  block: 'center',
                                });

                                const element = targetElement.$;
                                element.style.backgroundColor =
                                  'rgba(255, 255, 0, 0.3)';
                                element.style.borderBottom =
                                  '3px solid #FF9900';
                                element.style.transition =
                                  'background-color 0.3s ease, border-bottom 0.3s ease';

                                setTimeout(() => {
                                  element.style.backgroundColor = '';
                                  element.style.borderBottom = '';
                                }, 2000);
                              }
                            }
                          }
                        }}
                      >
                        Vai al testo
                      </Button>
                    </Col>
                  ))}
                </Row>
              </div>
            )}
          </div>
        </section>

        <Modal
          isOpen={isModalOpen}
          toggle={toggle}
          className="new-comment-modal"
        >
          <ModalHeader toggle={toggle} style={{ paddingBottom: '0px' }}>
            Aggiungi un commento
          </ModalHeader>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleSaveComment();
            }}
          >
            <ModalBody>
              <>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Label>Aggiungi un commento</Label>
                      <Input
                        type="text"
                        name="comment"
                        defaultValue={''}
                        placeholder="Aggiungi un commento"
                        onChange={(e) => setNewComment(e.target.value)}
                      />
                      {/* {errors.name === true && (
                        <FormText className="error-message">
                          {t('NAME_ERROR')}
                        </FormText>
                      )} */}
                    </FormGroup>
                  </Col>
                </Row>
              </>
            </ModalBody>
            <ModalFooter>
              <BtnOutlineRed
                type="button"
                className="uppercase"
                onClick={toggle}
              >
                {t('COMMON:CANCEL')}
              </BtnOutlineRed>
              <BtnOutlineGreen type="submit" className="uppercase">
                {t('COMMON:CONFIRM')}
              </BtnOutlineGreen>
            </ModalFooter>
          </Form>
        </Modal>

        <Modal isOpen={modalCompilation} toggle={toggleCompilation}>
          <ModalHeader toggle={toggleCompilation}>
            {t('MODAL_DRAFT')}
          </ModalHeader>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleValidateComments(toggleCompilation);
            }}
          >
            <ModalBody>
              <div className="max-width-form" style={{ textAlign: 'center' }}>
                Sei sicuro di voler salvare le modifiche?
              </div>
            </ModalBody>
            <ModalFooter>
              <BtnOutlineRed
                type="button"
                className="uppercase"
                onClick={toggleCompilation}
              >
                {t('COMMON:CANCEL')}
              </BtnOutlineRed>
              <BtnOutlineGreen type="submit" className="uppercase">
                {t('COMMON:CONFIRM')}
              </BtnOutlineGreen>
            </ModalFooter>
          </Form>
        </Modal>

        <Modal isOpen={modalDeleteComment} toggle={toggleDeleteComment}>
          <ModalHeader toggle={toggleDeleteComment}>
            {t('MODAL_DRAFT')}
          </ModalHeader>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleDeleteComment(deleteCommentId);
            }}
          >
            <ModalBody>
              <div className="max-width-form" style={{ textAlign: 'center' }}>
                Sei sicuro di voler cancellare il commento?
              </div>
            </ModalBody>
            <ModalFooter>
              <BtnOutlineRed
                type="button"
                className="uppercase"
                onClick={toggleDeleteComment}
              >
                {t('COMMON:CANCEL')}
              </BtnOutlineRed>
              <BtnOutlineGreen type="submit" className="uppercase">
                {t('COMMON:CONFIRM')}
              </BtnOutlineGreen>
            </ModalFooter>
          </Form>
        </Modal>

        <Modal isOpen={modalRevision} toggle={toggleRevision}>
          <ModalHeader toggle={toggleRevision}>{t('MODAL_DRAFT')}</ModalHeader>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleValidateCommentsRevision(toggleRevision);
            }}
          >
            <ModalBody>
              <div className="max-width-form" style={{ textAlign: 'center' }}>
                Sei sicuro di voler salvare e andare in fase di revisione?
              </div>
            </ModalBody>
            <ModalFooter>
              <BtnOutlineRed
                type="button"
                className="uppercase"
                onClick={toggleRevision}
              >
                {t('COMMON:CANCEL')}
              </BtnOutlineRed>
              <BtnOutlineGreen type="submit" className="uppercase">
                {t('COMMON:CONFIRM')}
              </BtnOutlineGreen>
            </ModalFooter>
          </Form>
        </Modal>

        <Modal isOpen={modalFirmed} toggle={toggleUpdateFirmed}>
          <ModalHeader toggle={toggleUpdateFirmed}>
            {t('MODAL_COMPILATION')}
          </ModalHeader>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              handleGeneratePDF();
            }}
          >
            <ModalBody>
              <div className="max-width-form" style={{ textAlign: 'center' }}>
                Sei sicuro di voler salvare e andare in fase di firma?
              </div>
            </ModalBody>
            <ModalFooter>
              <BtnOutlineRed
                type="button"
                className="uppercase"
                onClick={toggleUpdateFirmed}
              >
                {t('COMMON:CANCEL')}
              </BtnOutlineRed>
              <BtnOutlineGreen type="submit" className="uppercase">
                {t('COMMON:CONFIRM')}
              </BtnOutlineGreen>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default AgreementEditorPresentational;
