import React from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable, DateTimeField, Field } from '../../../commons/DataTable';
import ActionsField from './ActionsField';
import BadgeField from './BadgeField';
import { ADMIN_TYPES } from '../../../App';

function AgreementsTable({
  data,
  total,
  offset,
  limit,
  onFilter,
  profile,
  assemblyActived,
  handleFileListView,
  fetchAgreements,
}) {
  const [t] = useTranslation();

  return (
    <DataTable
      data={data}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      emptyText={t('COMMON:NO_DATA')}
    >
      <Field title="Nome contratto" source="name" className="text-center" />
      <Field
        title="Nome Condominio"
        source="condo.name"
        className="text-center"
      />
      <Field
        title="Codice Fiscale"
        source="condo.vatCode"
        className="text-center"
      />
      <Field
        title="Fornitore"
        source="supplier.businessName"
        className="text-center"
        visible={ADMIN_TYPES.includes(profile.type)}
      />
      <DateTimeField
        title="Data creazione"
        source="createdAt"
        format="DD/MM/YYYY"
      />
      <DateTimeField
        title="Ultima Modifica"
        source="updatedAt"
        format="DD/MM/YYYY"
      />
      <Field
        title="RAS"
        source="ras"
        className="text-center"
        visible={profile.type === 'SUPPLIER'}
      />
      <Field
        title="PSI/POS"
        source="psiPos"
        className="text-center"
        visible={ADMIN_TYPES.includes(profile.type) && !assemblyActived}
      />
      <BadgeField
        title="Stato Contratto"
        source="status"
        className="text-center"
      />
      <Field
        title="Firma"
        source="signature"
        className="text-center"
        visible={ADMIN_TYPES.includes(profile.type) && !assemblyActived}
      />
      <Field
        title="Archiviazione Sostitutiva"
        source="archiving"
        className="text-center"
        visible={ADMIN_TYPES.includes(profile.type) && !assemblyActived}
      />
      <ActionsField
        title="Azioni"
        handleFileListView={handleFileListView}
        assemblyMode={assemblyActived}
        fetchAgreements={fetchAgreements}
      />
    </DataTable>
  );
}

export default AgreementsTable;
