import axios from 'axios';

export const uploadAgreementFirmedFile = async ({
  data,
  token,
  agreementId,
}) => {
  try {
    const response = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_URL}/agreements/firmed/${agreementId}`,
      data,
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const uploadSuppCondoAgreement = async ({ data, token }) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/agreements`,
      data,
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const uploadSuppAdminAgreement = async ({ data, token }) => {
  try {
    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/agreements`,
      data,
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const deleteSuppCondoAgreement = async ({ agreementId, key, token }) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/agreements/${agreementId}`,
      data: { key },
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};

export const deleteSuppAdminAgreement = async ({ agreementId, key, token }) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_URL}/agreements/${agreementId}`,
      data: { key },
      headers: {
        Authorization: token,
      },
    });

    if (response instanceof Error) {
      return { error: response };
    }

    return response;
  } catch (error) {
    // Sentry TODO
    return { error };
  }
};
