import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../commons/Auth';
import { useLoading } from '../../commons/Loading';
import { useAlert } from 'react-alert';
import AgreementDetailsPresentational from './AgreementDetailsPresentational';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';
import axios from 'axios';
import { getSignedUrl } from '../../lib/fetch/files';
import './AgreementsDetail.scss';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const AgreementsDetail = ({ profile }) => {
  const { id: agreementId } = useParams();
  const [t] = useTranslation('AGREEMENTS');
  const [{ token, isAdmin } = {}] = useAuth();
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();

  const [agreement, setAgreement] = useState({});
  const [attachments, setAttachments] = useState([]);
  const [totalAttachments, setTotalAttachments] = useState(0);

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
  });

  const { limit, offset } = query;

  useEffect(() => {
    fetchAgreement();
  }, [limit, offset, agreementId]);

  const fetchAgreement = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agreements/details/${agreementId}`,
      {
        headers: { Authorization: token },
        params: {
          limit,
          offset,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = {} } = response;
    setAgreement(data);
    setAttachments(data.attachments);
    setTotalAttachments(data.attachments?.length);
  };

  function onFilter(queryDiff) {
    if (queryDiff.limit && queryDiff.limit !== limit) {
      queryDiff.offset = 0;
    }

    setQuery(queryDiff);
  }

  async function handleFileListView(key) {
    showLoading();

    const { data, error } = await getSignedUrl(key, token);

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    hideLoading();
    window.open(data.url, '_blank');
  }

  async function updateAttachments(selectedAttachments, toggle) {
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/agreements/attachments/${agreementId}`,
      { selectedAttachments },
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = {} } = response;

    toggle();
    fetchAgreement();
  }

  async function uploadPSIDocument(file, toggle) {
    showLoading();

    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/agreements/psi/${agreementId}`,
      data: file,
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    });

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = {} } = response;

    toggle();
    fetchAgreement();
    alert.success(t('COMMON:SUCCESS_UPLOAD'));
  }

  const uploadDocument = async (file, toggle) => {
    showLoading();

    const response = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/agreements/document/${agreementId}`,
      data: file,
      headers: {
        Authorization: token,
        'Content-Type': 'multipart/form-data',
      },
    });

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = {} } = response;

    fetchAgreement();
    toggle();
    alert.success(t('COMMON:SUCCESS_UPLOAD'));
  };

  return (
    <AgreementDetailsPresentational
      data={agreement}
      attachments={attachments}
      total={totalAttachments}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      profile={profile}
      query={query}
      handleFileListView={handleFileListView}
      updateAttachments={updateAttachments}
      uploadPSIDocument={uploadPSIDocument}
      uploadDocument={uploadDocument}
    />
  );
};

export default AgreementsDetail;
