import React, { useEffect, useState } from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useAuth } from '../../commons/Auth';
import { useLoading } from '../../commons/Loading';
import AgreementsModelingPresentational from './AgreementsModelingPresentational';
import axios from 'axios';
import { Redirect } from 'react-router-dom';

const AgreementsModeling = ({ profile, location, match = {} }) => {
  const { id: agreementId } = useParams();
  const [t] = useTranslation('AGREEMENTS');
  const alert = useAlert();
  const [{ token, isAdmin } = {}] = useAuth();
  const { showLoading, hideLoading } = useLoading();

  const [agreement, setAgreement] = useState({});
  const [selectedModels, setSelectedModels] = useState([]);

  const [redirectTo, setRedirectTo] = useState(null);

  const fetchAgreement = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agreements/single/${agreementId}`,
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = {} } = response;

    setAgreement(data);
  };

  const handleSelectedModels = (modelId) => {
    const updatedModels = selectedModels.map((model) => {
      if (model._id === modelId) {
        return { ...model, checked: !model.checked };
      }
      return model;
    });

    setSelectedModels(updatedModels);
  };

  const handleSave = async (toggle) => {
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/agreements/${agreementId}`,
      { draftTeamplate: selectedModels },
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    toggle();
    alert.success(t('COMMON:SUCCESS_UPDATE'));
    const { data = {} } = response;
    fetchAgreement();
  };

  const handleSaveCompilation = async (toggle) => {
    showLoading();

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/agreements/draft/${agreementId}`,
      { draftTeamplate: selectedModels },
      {
        headers: { Authorization: token },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    toggle();
    alert.success(t('COMMON:SUCCESS_UPDATE'));
    const { data = {} } = response;
    setAgreement(data);

    setRedirectTo(`/agreements-editor/${agreementId}`);
  };

  useEffect(() => {
    fetchAgreement();
  }, []);

  useEffect(() => {
    setSelectedModels(agreement._id ? agreement.draftTeamplate : []);
  }, [agreement]);

  if (redirectTo || agreement.status === 'COMPILED') {
    return <Redirect to={redirectTo} />;
  }

  return (
    <AgreementsModelingPresentational
      profile={profile}
      agreement={agreement}
      selectedModels={selectedModels}
      handleSelectedModels={handleSelectedModels}
      handleSaveCompilation={handleSaveCompilation}
      handleSave={handleSave}
    />
  );
};

export default AgreementsModeling;
