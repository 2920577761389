import React from 'react';
import { Col, CustomInput, FormGroup, Label, Row } from 'reactstrap';
import {
  BooleanField,
  DataTable,
  DateTimeField,
  Field,
} from '../../../commons/DataTable';
import { ContFilterYear, ContPaginationPage } from '../../../styled-components';
import generatePaginationOptions from '../../../lib/helpers/generatePaginationOptions';
import ActionsField from './ActionsField';
import { useTranslation } from 'react-i18next';
import SwitchField from './SwitchField';
import AddFileButton from './AddFileButton';
import DisponibilityField from './DisponibilityField';

const ProfileDocumentsPresentational = ({
  data,
  profile,
  query,
  total,
  onFilter,
  handleFileListView,
  uploadFile,
  handleDelete,
  documentTypes,
}) => {
  const { offset, limit } = query;

  const [t] = useTranslation();

  return (
    <div style={{ marginTop: '1.5rem' }}>
      <Row className="align-items-center">
        <Col sm="12" md="4">
          <ContFilterYear className="no-width">
            <Row>
              <Col xs="6">
                <Label>Stato Documenti</Label>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <CustomInput
                    type="select"
                    id="active"
                    name="active"
                    value={query.active}
                    onChange={({ target: { value: active } }) =>
                      onFilter({ active })
                    }
                  >
                    <option value="" defaultChecked>
                      Tutti
                    </option>
                    <option value="ACTIVE">Attivi</option>
                    <option value="NOT_ACTIVE">Scaduti</option>
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
          </ContFilterYear>
        </Col>
        <Col xs={8} style={{ marginBottom: 20, textAlign: 'end' }}>
          <AddFileButton
            uploadFile={uploadFile}
            profile={profile}
            documentTypes={documentTypes}
          />
        </Col>
      </Row>

      <DataTable
        data={data}
        total={total}
        offset={offset}
        limit={limit}
        onFilter={onFilter}
        emptyText={t('COMMON:NO_DATA')}
      >
        <Field title="Nome" source="file.name" className="text-left" />
        <Field title="Tipologia" source="type.name" className="text-left" />
        <DateTimeField
          title="Data Caricamento"
          source="file.createdAt"
          format="DD/MM/YYYY"
        />
        <DateTimeField
          title="Data Scadenza"
          source="expireDate"
          format="DD/MM/YYYY"
        />
        <Field title="Caricato da" source="uploadedBy" />
        <Field title="Email" source="uploadedByEmail" />
        <BooleanField title="Scaduto" source="expired" />
        <SwitchField
          title="Necessario per contratto"
          source="needed"
          profile={profile}
          disabled={true}
        />
        <DisponibilityField title="Disponibilità" />
        <ActionsField
          title="Azioni"
          handleFileListView={handleFileListView}
          deleteFileConfirm={handleDelete}
        />
      </DataTable>

      <ContPaginationPage>
        <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

        <FormGroup>
          <CustomInput
            type="select"
            className="pagination-select"
            id="perPage"
            name="perPage"
            value={query.limit}
            onChange={({ target: { value: limit } }) => {
              onFilter({ limit });
            }}
          >
            {generatePaginationOptions()}
          </CustomInput>
        </FormGroup>
      </ContPaginationPage>
    </div>
  );
};

export default ProfileDocumentsPresentational;
