import React from 'react';
import { useAlert } from 'react-alert';
import { useTranslation } from 'react-i18next';
import { useLoading } from '../../../commons/Loading';
import { useAuth } from '../../../commons/Auth';
import {
  deleteCondoFile,
  deleteFile,
  getSignedUrl,
} from '../../../lib/fetch/files';
import { uploadFileByUser } from '../../../lib/fetch/profiles';
import { uploadFileByCertifierToCondo } from '../../../lib/fetch/condos';
import { Col, CustomInput, FormGroup, Label, Row } from 'reactstrap';
import {
  BooleanField,
  DataTable,
  DateTimeField,
  Field,
} from '../../../commons/DataTable';
import SwitchField from './SwitchField';
import ActionsField from './ActionsField';
import AddFileButton from './AddFileButton';
import {
  ContFilterYear,
  ContPaginationPage,
  H4Styled,
  H5Styled,
} from '../../../styled-components';
import generatePaginationOptions from '../../../lib/helpers/generatePaginationOptions';
import DocumentTypeField from './DocumentTypeField';
import DisponibilityField from './DisponibilityField';
import TypeField from './TypeField';

const CondoDocuments = ({
  condo = {},
  fetchCondo,
  query,
  total,
  onFilter,
  condoFiles,
  uploadFile,
  handleDelete,
  documentTypes,
  RASTypes,
  getCondoFiles,
}) => {
  const [t] = useTranslation();
  const alert = useAlert();
  const { showLoading, hideLoading } = useLoading();
  const [{ token, profile = {}, isAdmin } = {}] = useAuth();

  const { limit, offset, active } = query;

  async function handleFileListView(key) {
    showLoading();

    const { data, error } = await getSignedUrl(key, token);

    if (error) {
      hideLoading();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    hideLoading();
    window.open(data.url, '_blank');
  }

  return (
    <>
      <Row
        xs={8}
        className="align-items-center"
        style={{ justifyContent: 'space-between' }}
      >
        <Col>
          <H5Styled>
            {t('ROYALTY:CONDO')}: {condo.name}
          </H5Styled>
        </Col>
      </Row>

      <Row className="align-items-center">
        <Col sm="12" md="4" className="select-wrapper">
          <ContFilterYear className="no-width">
            <Row>
              <Col xs="6">
                <Label>Stato Documenti</Label>
              </Col>
              <Col xs="6">
                <FormGroup>
                  <CustomInput
                    type="select"
                    id="active"
                    name="active"
                    value={active}
                    onChange={({ target: { value: active } }) =>
                      onFilter({ active })
                    }
                  >
                    <option value="" defaultChecked>
                      Tutti
                    </option>
                    <option value="ACTIVE">Attivi</option>
                    <option value="NOT_ACTIVE">Scaduti</option>
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
          </ContFilterYear>
        </Col>
        {!isAdmin && (
          <Col xs={8} style={{ marginBottom: 20, textAlign: 'end' }}>
            <AddFileButton
              uploadFile={uploadFile}
              profile={profile}
              documentTypes={documentTypes}
              RASTypes={RASTypes}
              condo={condo}
            />
          </Col>
        )}
      </Row>

      <DataTable
        data={condoFiles}
        total={total}
        offset={offset}
        limit={limit}
        onFilter={onFilter}
        emptyText={t('COMMON:NO_DATA')}
      >
        <Field title="Nome" source="file.name" className="text-left" />
        <TypeField title="Tipologia" source="type.name" className="text-left" />
        <DateTimeField
          title="Data Caricamento"
          source="file.createdAt"
          format="DD/MM/YYYY"
        />
        <DateTimeField
          title="Data Scadenza"
          source="expireDate"
          format="DD/MM/YYYY"
        />
        <Field title="Caricato da" source="uploadedBy" />
        <Field title="Email" source="profile.emailCommunication" />
        <BooleanField title="Scaduto" source="expired" />
        <SwitchField
          title="Necessario per contratto"
          source="needed"
          condo={condo}
          disabled={isAdmin}
          getCondoFiles={getCondoFiles}
        />
        <DisponibilityField title="Disponibilità" />
        <ActionsField
          title="Azioni"
          handleFileListView={handleFileListView}
          deleteFileConfirm={handleDelete}
        />
      </DataTable>

      <ContPaginationPage>
        <p>{t('COMMON:PAGINATION_PER_PAGE')}</p>

        <FormGroup>
          <CustomInput
            type="select"
            className="pagination-select"
            id="perPage"
            name="perPage"
            value={query.limit}
            onChange={({ target: { value: limit } }) => {
              onFilter({ limit });
            }}
          >
            {generatePaginationOptions()}
          </CustomInput>
        </FormGroup>
      </ContPaginationPage>
    </>
  );
};

export default CondoDocuments;
