import React from 'react';

function ContractsCheckbox({ data, selected = [], onSelectChange = () => {} }) {
  return (
    <input
      type="checkbox"
      checked={selected.find((value) => value._id === data._id)}
      disabled={!data._id}
      onChange={(e) => {
        e.stopPropagation();
        onSelectChange(data);
      }}
    />
  );
}

export default ContractsCheckbox;
