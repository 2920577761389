import React from 'react';
import { BtnModalTable } from '../../styled-components';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ActionsField({ data, profile }) {
  const [t] = useTranslation('NOTIFICATIONS');
  const history = useHistory();

  return (
    <>
      {data.agreement ? (
        <BtnModalTable
          type="button"
          className="mx-2 px-2"
          onClick={(event) => {
            event.stopPropagation();
            history.push(`/agreements-details/${data.agreementId}`);
          }}
        >
          {t('DETAIL')}
        </BtnModalTable>
      ) : (
        <>-</>
      )}
    </>
  );
}

export default ActionsField;
