import React, { useEffect, useState } from 'react';
import ActivitiesDetailsPresentational from './ActivitiesDetailPresentational';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../commons/Auth';
import { useLoading } from '../../commons/Loading';
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';
import { useAlert } from 'react-alert';
import axios from 'axios';
import { uploadSuppCondoAgreement } from '../../lib/fetch/agreements';
import { Redirect } from 'react-router-dom';

const { REACT_APP_DEFAULT_PAGINATION_LIMIT = 50 } = process.env;

const ActivitiesDetail = ({ profile }) => {
  const { id: activityId } = useParams();
  const [t] = useTranslation('ACTIVITIES');
  const [{ token, isAdmin } = {}] = useAuth();

  const [condos, setCondos] = useState([]);
  const [total, setTotal] = useState(0);
  const { showLoading, hideLoading } = useLoading();
  const alert = useAlert();

  // Stepper 1
  const [documentTypes, setDocumentTypes] = useState([]);
  const [agreementTypes, setAgreementTypes] = useState([]);
  const [suppliers, setSuppliers] = useState([]);

  const [selectedCondo, setSelectedCondo] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  // Stepper 2
  const [documents, setDocuments] = useState([]);
  const [totalDocuments, setTotalDocuments] = useState(0);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectedAll, setSelectedAll] = useState(false);

  const [redirectTo, setRedirectTo] = useState(null);

  // Parametri Legati ai Documenti
  const [queryDocuments, setQueryDocuments] = useState({
    limitDocuments: 10,
    offsetDocuments: 0,
  });
  const { limitDocuments, offsetDocuments } = queryDocuments;

  function onFilterDocuments(queryDiff) {
    if (
      queryDiff.limitDocuments &&
      queryDiff.limitDocuments !== limitDocuments
    ) {
      queryDiff.offsetDocuments = 0;
    }
    setQueryDocuments(queryDiff);
  }

  const [query, setQuery] = useQueryParams({
    limit: withDefault(NumberParam, +REACT_APP_DEFAULT_PAGINATION_LIMIT),
    offset: NumberParam,
    name: withDefault(StringParam, ''),
    vatCode: withDefault(StringParam, ''),
    maintainer: withDefault(StringParam, ''),
    condosState: withDefault(StringParam, ''),
  });

  const { limit, offset, name, vatCode, maintainer, condosState } = query;

  const fetchCondos = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/activities/${activityId}`,
      {
        headers: { Authorization: token },
        params: {
          limit,
          offset,
          name,
          vatCode,
          maintainer,
          condosState,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers = {} } = response;
    setCondos(data.condos);
    setTotal(+headers['x-total-count']);
  };

  function onFilter(queryDiff) {
    if (
      (queryDiff.maintainer && queryDiff.maintainer !== maintainer) || // first search
      (maintainer && queryDiff.maintainer === '') || // search deleted
      (queryDiff.name && queryDiff.name !== name) || // first search
      (name && queryDiff.name === '') || // search deleted
      (queryDiff.vatCode && queryDiff.vatCode !== vatCode) || // first search
      (vatCode && queryDiff.vatCode === '') || // search deleted
      (queryDiff.condosState && queryDiff.condosState !== condosState) || // first search
      (condosState && queryDiff.condosState === '') || // search deleted
      (queryDiff.limit && queryDiff.limit !== limit)
    ) {
      queryDiff.offset = 0; // return to first page
    }

    setQuery(queryDiff);
  }

  // Fetch attivitá per Select Attivitá
  const fetchDocumentTypes = async () => {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/document-types`,
      {
        headers: { Authorization: token },
        params: {
          limit: 9999,
          offset: 0,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [] } = response;

    setDocumentTypes(data.documentTypes);
  };

  // Fetch attivitá per Select Attivitá
  const fetchAgreementTypes = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agreement-types`,
      {
        headers: { Authorization: token },
        params: {
          limit: 9999,
          offset: 0,
          activityId,
        },
      }
    );

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [] } = response;

    setAgreementTypes(data.agreementTypes);
  };

  // Fetch Fornitori per Select Fornitori
  const fetchSuppliers = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agreements/suppliers`,
      {
        headers: { Authorization: token },
        params: { activityId },
      }
    );

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [] } = response;

    setSuppliers(data.suppliers);
  };

  // Fetch Documenti
  async function fetchDocuments() {
    showLoading();

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/documents/${profile._id}`,
      {
        headers: { Authorization: token },
        params: {
          limit: limitDocuments,
          offset: offsetDocuments,
          needed: true,
          forAgreements: true,
          selectedCondo,
          selectedSupplier,
        },
      }
    );

    hideLoading();

    if (response instanceof Error) {
      return alert.error(t('COMMON:GENERIC_ERROR'));
    }

    const { data = [], headers } = response;

    setDocuments(data.documents);
    setTotalDocuments(+headers['x-total-count']);
  }

  const onSelectChange = (_id, typeId) => {
    if (selectedDocuments.find((value) => value._id === _id)) {
      setSelectedAll(false);
      return setSelectedDocuments((selectedDocuments) =>
        selectedDocuments.filter((value) => value._id !== _id)
      );
    }
    setSelectedDocuments((selectedDocuments) => [
      ...selectedDocuments,
      { _id, typeId },
    ]);
  };

  const onSelectAll = (selectAll) => {
    setSelectedAll(selectAll);
    if (!selectAll) {
      return setSelectedDocuments([]);
    }
    setSelectedDocuments(
      documents
        .filter((document) => document._id !== undefined)
        .map(({ _id, typeId }) => {
          return { _id, typeId };
        })
    );
  };

  // Caricamento nuovo contratto
  const uploadAgreements = async (newAgreement, toggle, redirect) => {
    showLoading();

    const { error, data } = await uploadSuppCondoAgreement({
      data: newAgreement,
      token,
    });

    hideLoading();

    if (error) {
      toggle();
      alert.error(t('COMMON:GENERIC_ERROR'));
      return;
    }

    if (redirect) {
      setRedirectTo(`/agreements-modeling/${data[0]._id}`);
      return;
    }

    fetchCondos();
    toggle();
    alert.success(t('COMMON:SUCCESS_UPLOAD'));
  };

  useEffect(() => {
    fetchCondos();
  }, [limit, offset, name, vatCode, maintainer, condosState]);

  useEffect(() => {
    fetchDocumentTypes();
    fetchSuppliers();
    fetchAgreementTypes();
  }, []);

  useEffect(() => {
    if (selectedSupplier) {
      fetchDocuments();
    }
  }, [limitDocuments, offsetDocuments, selectedSupplier]);

  useEffect(() => {
    setSelectedDocuments([]);
  }, [selectedSupplier]);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <ActivitiesDetailsPresentational
      data={condos}
      total={total}
      offset={offset}
      limit={limit}
      onFilter={onFilter}
      profile={profile}
      query={query}
      activityId={activityId}
      documentTypes={documentTypes}
      agreementTypes={agreementTypes}
      suppliers={suppliers}
      documents={documents}
      totalDocuments={totalDocuments}
      limitDocuments={limitDocuments}
      offsetDocuments={offsetDocuments}
      onFilterDocuments={onFilterDocuments}
      onSelectChange={onSelectChange}
      onSelectAll={onSelectAll}
      selectedAll={selectedAll}
      selectedDocuments={selectedDocuments}
      uploadAgreements={uploadAgreements}
      setSelectedSupplier={setSelectedSupplier}
      setSelectedCondo={setSelectedCondo}
    />
  );
};

export default ActivitiesDetail;
